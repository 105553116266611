import React, { useCallback, useRef, useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CButton } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { setListSegmentReport, setListSegmentReload } from '../../../../../actions/subscriber';
import { API_CLIENT_SEGMENT_REPORT, BQ_JOB_STATUS } from '../../../../../constants';
import { callTokenApi } from '../../../../../apiCaller';
import { camelCase, useOutsideHandling } from '../../../../../utils';
import SegmentDropdown from './SegmentDropdown';
import { EventExplorerContext, handleGetSortSegment } from '../EventExplorer';
import { TooltipHover } from '../../../../general/TooltipHover';
import { iconChartPieNoColor } from '../../../../../assets/icons/logo-negative';

const AddSegments = ({ listSegmentApplyOff }) => {
     const dispatch = useDispatch();
     const {
          reportName,
          segmentSticky,
          setSort,
          sort,
          filterMetric,
          disableSort,
          listSegmentReportApply,
          setListSegmentReportApply,
          showData,
          isSaveReport,
          defaultConfig,
     } = useContext(EventExplorerContext);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const user = useSelector((state) => state.theme.user);
     const listSegmentReport = useSelector((state) => state.subscriber.listSegmentReport);

     const [showPopup, setShowPopup] = useState(false);
     const [segmentsSelected, setSegmentsSelected] = useState();
     const [saveLoading, setSaveLoading] = useState(false);
     const [segmentsEdit, setSegmentsEdit] = useState([]);
     const accountId = activeAccount.id;
     const userId = user.id;

     useEffect(() => {
          if (listSegmentApplyOff?.length > 0) {
               setSegmentsEdit(listSegmentApplyOff);
          }
     }, [listSegmentApplyOff]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          setSegmentsSelected(listSegmentReportApply);
     }, [listSegmentReportApply]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleClickBubble = () => {
          if (!showData) {
               return;
          }
          setTimeout(() => {
               if (!showPopup) {
                    setShowPopup(true);
               }
          }, 100);
     };

     const clickOutside = useCallback(() => {
          setShowPopup(false);
          setSegmentsEdit([]);
     }, []);

     const wrapperRef = useRef(null);
     useOutsideHandling(wrapperRef, clickOutside);

     const handleConfirmDelete = (id) => {
          if (!showData) {
               return;
          }
          const segmentToClose = segmentsSelected.find((item) => item.id === id);
          const segmentsEdit = [
               {
                    id: segmentToClose.id,
                    segmentReportId: segmentToClose.segmentReportId,
                    apply: false,
                    applyChart: false,
                    type: 'update',
                    isUpdateAt: true,
               },
          ];
          const data = {
               reportName,
               accountId,
               segmentsEdit,
               segmentSticky,
               userId,
               isSaveReport,
               currentSegmentSticky: defaultConfig?.segmentSticky,
               listSegmentReportApply,
          };
          const changeSort = filterMetric.length && sort.fieldName && sort.fieldName.includes(camelCase(segmentsEdit[0].id));

          const handleApiResponse = (response) => {
               if (response.status === 200) {
                    const { segmentReport } = response.data;
                    const newUpdate = [...listSegmentReport].map((seg) => {
                         const existed = segmentReport.find((item) => item.segmentId === seg.id);
                         if (existed) {
                              seg.apply = existed.apply;
                              seg.orderBy = existed.updated_at;
                         }
                         return seg;
                    });
                    const newListSegmentApply = newUpdate.filter((item) => item.apply);
                    if (newListSegmentApply.length > 0) {
                         if (changeSort) {
                              setSort(
                                   handleGetSortSegment({
                                        listSegmentReportApply: newUpdate.filter((item) => item.apply && item.queryStatus === BQ_JOB_STATUS.DONE),
                                        filterMetric,
                                   })
                              );
                         }
                    } else if (filterMetric[0] && !disableSort) {
                         setSort({
                              fieldName: filterMetric[0].value,
                              isDesc: true,
                         });
                    }
                    dispatch(setListSegmentReport(newUpdate));
                    dispatch(setListSegmentReload(newUpdate));
                    setSegmentsSelected(newUpdate.filter((item) => item.apply && item.queryStatus === BQ_JOB_STATUS.DONE));
                    setListSegmentReportApply(
                         newUpdate
                              .filter((item) => item.apply && item.queryStatus === BQ_JOB_STATUS.DONE)
                              .sort((a, b) => {
                                   if (a.isCustom !== b.isCustom) {
                                        return a.isCustom ? 1 : -1;
                                   }
                                   return new Date(a.orderBy) - new Date(b.orderBy);
                              })
                    );
                    setSaveLoading(false);
                    toast.success('Remove segment success!');
               }
          };

          if (segmentsEdit && segmentsEdit.length) {
               setSaveLoading(true);

               const isCallApi = !isSaveReport || segmentSticky;
               if (isCallApi) {
                    callTokenApi(`${API_CLIENT_SEGMENT_REPORT}`, 'PUT', data).then((response) => {
                         handleApiResponse(response);
                    });
               } else {
                    const segmentReport = data.segmentsEdit.map((seg) => {
                         return {
                              accountId: data.accountId,
                              segmentId: seg.id,
                              reportName: data.reportName,
                              updated_at: new Date(),
                              apply: seg.apply,
                              applyChart: seg.applyChart,
                              userId: data.userId,
                         };
                    });
                    const response = { status: 200, data: { segmentReport } };
                    handleApiResponse(response);
               }
          }
     };

     return (
          <div className="custom-segment-dropdown d-flex align-items-center">
               <div className="segment-action d-flex align-items-center">
                    <div className="d-flex align-item-center flex-wrap justify-content-end box-segment">
                         {segmentsSelected && segmentsSelected.length > 0 ? (
                              segmentsSelected.map((item, index) => (
                                   <TooltipHover title={`S${index + 1}: ${item.name}`} description={item.description} icon={iconChartPieNoColor} placement="top">
                                        <div className="rule-segment d-flex align-item-center" key={index}>
                                             <div className="rule-segment-item d-flex align-items-center">
                                                  <div className="mr-2 rule-segment-item-icon d-flex align-items-center">
                                                       <CIcon
                                                            name="iconChartPie"
                                                            style={{ cursor: 'pointer', width: '16px', height: '16px', marginRight: '5px' }}
                                                       />
                                                       <span className="rule-segment-item-level">S{index + 1}</span>
                                                  </div>
                                                  <div className="d-flex align-items-center rule-segment-item-info">
                                                       <p className="mr-3 mb-0" style={{maxWidth: '50px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>{item.name}</p>
                                                       <CIcon
                                                            name="iconClose"
                                                            className="icon-close"
                                                            style={{
                                                                 cursor: saveLoading ? 'not-allowed' : 'pointer',
                                                                 width: '9px',
                                                                 height: '9px',
                                                                 pointerEvents: saveLoading ? 'none' : 'auto',
                                                                 opacity: saveLoading ? 0.5 : 1
                                                            }}
                                                            onClick={() => handleConfirmDelete(item.id)}
                                                       />
                                                  </div>
                                             </div>
                                        </div>
                                   </TooltipHover>
                              ))
                         ) : (
                              <span className="mr-3 no-segment">No segments added</span>
                         )}
                    </div>
                    <div className="btn-add-segments">
                         <CButton className="text-capitalize" onClick={handleClickBubble}>
                              <i className="fal fa-plus mr-1"></i>
                              Add Segments
                         </CButton>
                    </div>
               </div>
               {showPopup && (
                    <div className="segment-dropdown" ref={wrapperRef}>
                         <SegmentDropdown
                              accountId={accountId}
                              segmentsEdit={segmentsEdit}
                              setSegmentsEdit={setSegmentsEdit}
                              setSegmentsSelected={setSegmentsSelected}
                              setShowPopup={setShowPopup}
                         />
                    </div>
               )}
          </div>
     );
};

export default AddSegments;
