import CIcon from '@coreui/icons-react';
import { CBadge, CButton, CCard, CCardBody, CSpinner } from '@coreui/react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setAsideShow, setFlexibleModal } from '../../../../actions/common';
import {
     checkRelatedBeforeDisableEvent,
     fetchGoals,
     handleFetchFormCategories,
     reloadConversionRules,
     setActiveFeatures,
     setConversionRules,
     setCustomFormTargetRules,
     setEngagementRules,
     setLimitationType,
     setNativeECommerce,
     setNewLayout,
     setShowBlockAccountPopup,
     setSubscriberState,
     setTriggerRules,
     toggleAsideLimitationHit,
} from '../../../../actions/subscriber';
import { callTokenApi } from '../../../../apiCaller';
import { CLIENT_LISTENER_DISABLE, CLIENT_LISTENER_ENABLE, CLIENT_LISTENER_ENABLE_ALL_FEATURES, LIMITATION_TYPE, LISTENER_CODE, SPECIAL_FEATURE_LIST } from '../../../../constants';
import { getEnabledListeners, getListenerColorIcon, getListenerRoutes } from '../../../../helpers/cms/subscriber';
import { toastError } from '../../../../utils';
import { InstructionPopup, SwitchPopup } from '../../../general/popup';
import VariableInRules from '../../../general/popup/VariableInRules';
import CSwitch from '../../../migration/CSwitch';

const ListenersItems = ({ listeners, filterType }) => {
     const history = useHistory();
     const dispatch = useDispatch();
     const newLayout = useSelector((state) => state.subscriber.newLayout);
     const accountRoutes = useSelector((state) => state.subscriber.routes);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const activeFeatures = useSelector((state) => state.subscriber.activeFeatures);
     const accountListeners = useSelector((state) => state.subscriber.accountListeners);
     const browserRules = useSelector((state) => state.subscriber.browserRules);
     const monitoringRules = useSelector((state) => state.subscriber.monitoringRules);
     const customVariableRules = useSelector((state) => state.subscriber.customVariableRules);
     const listenerFormsEnabled = useSelector((state) => state.subscriber.listenerFormsEnabled);
     const containerId = get(activeAccount, 'gtmInfo.currentContainer.containerId');
     const { showNewLayout } = newLayout;
     const { numberOfListeners, numberOfPremium } = activeAccount;
     const pathName = history.location.pathname;

     const [turningOnPopup, setTurningOnPopup] = useState(false);
     const [turningOffPopup, setTurningOffPopup] = useState(false);
     const [tempListener, setTempListener] = useState();
     const [isLoading, setIsLoading] = useState(false);
     const [learnVideoIndex, setLearnVideoIndex] = useState(-1);
     const [relatedMessage, setRelatedMessage] = useState('');

     const learnVideoPopupAttrs = learnVideoIndex > -1 ? { ...listeners[learnVideoIndex].videoOverview?.popup } : null;

     let specificFeatures = activeFeatures.filter((feature) => {
          let strToCompare = feature.code.toLowerCase();

          return SPECIAL_FEATURE_LIST.some((specialItem) => strToCompare.includes(specialItem));
     });

     useEffect(() => {
          const libraryWrapper = document.querySelector('.listeners .library-wrapper');
          if (libraryWrapper) {
               libraryWrapper.scrollTo(0, 0);
          }
     }, [filterType]);

     const toggleListener = (listener, status) => {
          setTempListener(listener);

          const successNext = (message) => {
               if (message) {
                    setRelatedMessage(message);
               } else {
                    setTurningOffPopup(true);
               }
               setIsLoading(false);
          }

          if (status === 'on') {
               setIsLoading(true);
               dispatch(
                    checkRelatedBeforeDisableEvent({
                         accountId: activeAccount.id,
                         type: 'listener',
                         listenerId: listener.id,
                         containerId,
                         successNext,
                    })
               );
          } else {
               const { packageNumberOfListeners: maxListeners, packageNumberOfPremium: maxPremium } = activeAccount;

               if (maxListeners !== -1 && numberOfListeners >= maxListeners) {
                    // Used listeners are greater than or equal available listeners
                    if (maxPremium !== -1 && listener.premium && numberOfPremium >= maxPremium) {
                         dispatch(setLimitationType(LIMITATION_TYPE.PREMIUM));
                    } else {
                         dispatch(setLimitationType(LIMITATION_TYPE.LISTENER));
                    }

                    dispatch(toggleAsideLimitationHit());
                    dispatch(setAsideShow(true));
               } else if (maxPremium !== -1 && listener.premium && numberOfPremium >= maxPremium) {
                    // Used premium listeners & features are greater than or equal available premium listeners and features
                    dispatch(toggleAsideLimitationHit());
                    dispatch(setAsideShow(true));
               } else {
                    setTurningOnPopup(true);
               }
          }
     };

     const acceptSwitching = () => {
          setIsLoading(true);
          const status = tempListener.configStatus ? 'on' : 'off';
          let urlToCall = '';
          let usedListeners = numberOfListeners;
          let usedPremium = numberOfPremium;

          if (status === 'on') {
               // Disable
               setTurningOffPopup(false);
               urlToCall = CLIENT_LISTENER_DISABLE;
               usedListeners = usedListeners - 1;
               usedPremium = tempListener.premium ? usedPremium - 1 : usedPremium;
          } else {
               // Enable
               setTurningOnPopup(false);
               urlToCall = CLIENT_LISTENER_ENABLE;
               usedListeners = usedListeners + 1;
               usedPremium = tempListener.premium ? usedPremium + 1 : usedPremium;
          }

          const data = {
               accountId: activeAccount.id,
               listenerId: tempListener.id,
          };

          const nextDispatch = {
               setIsLoading,
          };

          callTokenApi(urlToCall, 'POST', data)
               .then(async (response) => {
                    if (response.status === 200) {
                         let newListener = {};
                         let listenerIndex = 0;

                         accountListeners.some((listener, index) => {
                              if (tempListener.id === listener.id) {
                                   listenerIndex = index;
                                   newListener = listener;
                                   newListener.configStatus = !newListener.configStatus;

                                   return true;
                              }

                              return false;
                         });

                         let newAccountListeners = [...accountListeners];
                         newAccountListeners[listenerIndex] = newListener;
                         let newActiveAccount = { ...activeAccount };
                         newActiveAccount.numberOfListeners = usedListeners;
                         let newSpecificFeatures = null;

                         if (status === 'on') {
                              // Disable
                              // Turn off a listener, which will disable all features and remove all rules of that listener
                              // So we have to get the number of used premium features/listeners
                              // and rules again to set to activeAccount
                              newActiveAccount.numberOfPremium = response.data.numberOfPremium;
                              newActiveAccount.numberOfCusVariablesRules = response.data.numberOfRule;
                              newActiveAccount.numberOfCusBrowserRules = response.data.numberOfBrowser;
                              newActiveAccount.numberOfEventMonitor = response.data.numberOfMonitor;
                              newActiveAccount.saveReportingValues = response.data.saveReportingValues;

                              let newActiveFeatures = activeFeatures.filter((feature) => feature.listenerId !== tempListener.id);
                              if (newActiveFeatures.length < activeFeatures.length) {
                                   dispatch(setActiveFeatures(newActiveFeatures));
                              }

                              // Remove custom values and monitoring from specificFeatures
                              newSpecificFeatures = specificFeatures.filter((item) => item.listenerId !== tempListener.id);

                              const newBrowserRules = browserRules ? browserRules.filter((rule) => rule.listenerId !== tempListener.id) : null;
                              const newMonitoringRules = monitoringRules ? monitoringRules.filter((rule) => rule.listenerId !== tempListener.id) : null;
                              const newCustomVariableRules = customVariableRules ? customVariableRules.filter((rule) => rule.listenerId !== tempListener.id) : null;
                              dispatch(
                                   setSubscriberState({
                                        browserRules: newBrowserRules,
                                        monitoringRules: newMonitoringRules,
                                        customVariableRules: newCustomVariableRules,
                                   })
                              );

                              dispatch(setEngagementRules([]));

                              // Wizard Form Categories
                              const newListenerFormsEnabled = [...listenerFormsEnabled];
                              const listenerIndex = newListenerFormsEnabled.findIndex(listener => listener.id === newListener.id)
                              if (listenerIndex > -1) {
                                   newListenerFormsEnabled.splice(listenerIndex, 1)
                              }

                              if (tempListener.code === 'nativeDataLayers') {
                                   dispatch(setNativeECommerce(false));
                              }

                              if (pathName.includes(newListener.alias)) {
                                   history.push('/');
                                   dispatch(setFlexibleModal({ show: false, component: '' }));
                              }
                         } else {
                              // Enable
                              newActiveAccount.numberOfPremium = usedPremium;

                              // Reset custom form target rules when enabling custom form listener
                              if (tempListener.code === 'customForms') {
                                   dispatch(setCustomFormTargetRules(null));
                              }

                              await activeAllFeatures({ newActiveAccount, newAccountListeners });
                              dispatch(setNewLayout({ activeSubNavItem: tempListener.type, showNavChild: true })); // Show Active Listeners dropdown when enable listener

                              // Wizard Form Categories
                              const newListenerFormsEnabled = [...listenerFormsEnabled];
                              newListenerFormsEnabled.push(newListener)
                         }

                         const enabledListeners = getEnabledListeners(newAccountListeners);
                         const listenerRoutes = getListenerRoutes(enabledListeners, newSpecificFeatures || specificFeatures, activeAccount.secondId);
                         const newAccountRoutes = [...accountRoutes].filter((route) => !listenerRoutes.some((listenerRoute) => listenerRoute.path === route.path));
                         const newRoutes = [...newAccountRoutes, ...listenerRoutes];

                         dispatch(
                              setSubscriberState({
                                   activeAccount: newActiveAccount,
                                   routes: newRoutes,
                                   accountListeners: newAccountListeners,
                                   activeListeners: enabledListeners,
                                   eventOptions: {
                                        listenerId: '',
                                        events: [],
                                   },
                              })
                         );

                         // if (newListener.configStatus === true) {
                         //      // High light listener nav in sidebar when turn on
                         //      dispatch(
                         //           setSubscriberState({
                         //                activeListener: newListener,
                         //                elementJustActivated: {
                         //                     id: newListener.id,
                         //                     type: newListener.type,
                         //                },
                         //           })
                         //      );
                         //      setTimeout(() => {
                         //           dispatch(
                         //                setSubscriberState({
                         //                     elementJustActivated: null,
                         //                })
                         //           );
                         //      }, 500);
                         // }

                         dispatch(setTriggerRules([]));
                         dispatch(setConversionRules([]));
                         dispatch(reloadConversionRules());
                         dispatch(handleFetchFormCategories(nextDispatch));

                         if (['Ecommerce', 'eCommerce'].includes(tempListener.type) || tempListener.code === "nativeDataLayers" ) {
                              dispatch(fetchGoals(activeAccount.id, activeAccount.secondId))
                         }

                         toast.success(`Listener turning ${status === 'on' ? 'off' : 'on'}!`);
                    } else {
                         if (response.data.accountBlocked) {
                              dispatch(setShowBlockAccountPopup(true));
                         } else {
                              toastError(response);
                         }
                    }
               })
               .finally(() => setIsLoading(false));
     };

     const activeAllFeatures = async ({ newActiveAccount, newAccountListeners }) => {
          if (showNewLayout) {
               const data = {
                    accountId: activeAccount.id,
                    listenerId: tempListener.id,
               };
               await callTokenApi(CLIENT_LISTENER_ENABLE_ALL_FEATURES, 'POST', data).then((response) => {
                    if (response.status === 200) {
                         const accountFeatureActive = response.data.accountFeatureActive;
                         if (accountFeatureActive) {
                              const enabledListeners = getEnabledListeners(newAccountListeners);
                              dispatch(
                                   setSubscriberState({
                                        activeAccount: newActiveAccount,
                                        accountListeners: newAccountListeners,
                                        activeListeners: enabledListeners,
                                        activeFeatures: accountFeatureActive,
                                   })
                              );
                         }
                    } else {
                         toastError(response);
                    }
               });
          }
     };

     const handleLinkToPageDetail = (alias) => {
          history.push(`/${activeAccount.secondId}/listeners/${alias}`);
          dispatch(setFlexibleModal({ show: false, component: '' }));
     };

     return (
          <div className='library-wrapper'>
               <div className='listener-items row-items'>
                    {listeners
                         .sort((a, b) => a.name.localeCompare(b.name))
                         .map((listener, listenerIndex) => {
                              const { name, premium, description, configStatus, videoOverview } = listener;
                              let iconSrc = getListenerColorIcon(listener);
                              const isDisableTurnOff = configStatus && [LISTENER_CODE.PAGE_VIEW, LISTENER_CODE.SESSION_ENGAGEMENT, LISTENER_CODE.USER_SOURCE].includes(listener.code);

                              if (listener.name === "Page Engagement") return null;

                              return (
                                   <div className='listener-item' key={name}>
                                        <CCard className={premium ? 'listener-premium-card' : ''}>
                                             <CCardBody>
                                                  <div className='card-title d-flex justify-content-between w-100'>
                                                       <div className='d-flex'>
                                                            <img src={iconSrc} alt='Icon' className='icon-20 mr-2' />
                                                            <h4>{name}</h4>
                                                       </div>
                                                       {premium && <CBadge className='label-top-card color-premium'>Premium</CBadge>}
                                                  </div>

                                                  <div className='card-text h-100'>{description && <p className='f-14'>{description}</p>}</div>
                                                  <div
                                                       className={`card-icon d-flex ${videoOverview && videoOverview.isOn ? 'justify-content-between' : 'justify-content-end'
                                                            }`}
                                                  >
                                                       {videoOverview && videoOverview.isOn && (
                                                            <CButton className='btn-learn-video btn' onClick={() => setLearnVideoIndex(listenerIndex)}>
                                                                 <CIcon icon='arrow-play-radius' height={13} /> <span>Learn</span>
                                                            </CButton>
                                                       )}
                                                       <div className='card-header-actions'>
                                                            {showNewLayout && configStatus && (
                                                                 <CButton className='action-open-menu mr-2' onClick={() => handleLinkToPageDetail(listener.alias)}>
                                                                      <CIcon icon='cil-settings' height={16} />
                                                                 </CButton>
                                                            )}
                                                            {isLoading && listener.id === tempListener.id && <CSpinner color='primary' size='sm' className='mr-2' />}
                                                            <CSwitch
                                                                 className='float-right mb-0'
                                                                 color={configStatus ? 'success' : 'light'}
                                                                 checked={!!configStatus} // !!false = false, !!undefined = false, !!true = true
                                                                 value={configStatus ? 'on' : 'off'}
                                                                 size='lg'
                                                                 tabIndex='0'
                                                                 shape='pill'
                                                                 onChange={(e) => toggleListener(listener, e.target.value)}
                                                                 disabled={isLoading || isDisableTurnOff}
                                                            />
                                                       </div>
                                                  </div>
                                             </CCardBody>
                                        </CCard>
                                   </div>
                              );
                         })}
                    <InstructionPopup show={learnVideoIndex > -1} onClose={() => setLearnVideoIndex(-1)} {...learnVideoPopupAttrs} />
                    <SwitchPopup show={turningOnPopup} isLoading={isLoading} onAccept={acceptSwitching} onCancel={() => setTurningOnPopup(false)}>
                         <h2>Enable Listener</h2>
                         <div className='confirm-save-content'>
                              You're about to enable this listener. You will still need to publish your changes before this goes live.
                         </div>
                    </SwitchPopup>
                    <SwitchPopup show={turningOffPopup} onAccept={acceptSwitching} onCancel={() => setTurningOffPopup(false)}>
                         <h2>Disable Listener</h2>
                         <div className='confirm-save-content'>
                              Once a listener is disabled, all settings and rules for it will be lost and cannot be restored. Are you sure you want to make this change?
                         </div>
                    </SwitchPopup>
                    {/* <ConfirmRemovePopup show={variableInConverions} onClose={() => setVariableInConverions(false)} onAccept={handleAcceptRedirect} acceptText={'Go to Web Conversions'}>
                         <p>This category is used in some conversions. You must remove it from all existing conversions before you can delete it.</p>
                    </ConfirmRemovePopup> */}
                    <VariableInRules show={relatedMessage ? true : false} onClose={() => setRelatedMessage('')}>
                         <p>{relatedMessage}</p>
                    </VariableInRules>
               </div>
          </div>
     );
};

ListenersItems.propTypes = {
     listeners: PropTypes.array,
};

export default ListenersItems;
