import CIcon from '@coreui/icons-react';
import Tippy from '@tippyjs/react';
import { useRef, useState } from 'react';

const positionPath = {
     'top-start':
          '<svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M-2.11057e-07 4.17157L-3.93403e-07 0L9 -3.93403e-07L3.41421 5.58579C2.15428 6.84572 -1.33172e-07 5.95338 -2.11057e-07 4.17157Z" fill="#18222B"/></svg>',
     'bottom-start':
          '<svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M-2.11057e-07 2.82843L-3.93402e-07 7L9 7L3.41421 1.41421C2.15428 0.154284 -1.33172e-07 1.04662 -2.11057e-07 2.82843Z" fill="#18222B"/></svg>',
     'left-start':
          '<svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.17157 0H0V9L5.58579 3.41421C6.84572 2.15428 5.95338 0 4.17157 0Z" fill="#18222B"/></svg>',
     'right-start':
          '<svg width="6" height="13" viewBox="0 0 6 13"><g id="b671136e-83cf-4fb9-b98e-be51e54c953e" data-name="Layer 2"><g id="b5fae9b2-5e0b-421a-8a35-db7330bc1b57" data-name="Layer 1"><g id="b24f8614-20ef-4cec-a50a-7ced35d16814" data-name="Layer 2"><g id="fa01feaf-579e-4c20-ba60-43d232f559d5" data-name="Layer 1-2"><path d="M6,0H4.14c-7,0-3,6.06-3,6.06L6,13V0Z" style="fill:#19222b"></path></g></g></g></g></svg>',
     'right-end':
          '<svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.82843 9H7V0L1.41421 5.58579C0.154284 6.84572 1.04662 9 2.82843 9Z" fill="#18222B"/></svg>',
     'left-end':
          '<svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.17157 9H0V0L5.58579 5.58579C6.84572 6.84572 5.95338 9 4.17157 9Z" fill="#18222B"/></svg>',
     'top-end':
          '<svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 4.17157L9 0L3.93403e-07 -3.93403e-07L5.58579 5.58579C6.84572 6.84572 9 5.95338 9 4.17157Z" fill="#18222B"/></svg>',
     'bottom-end':
          '<svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 2.82843L9 7L3.93402e-07 7L5.58579 1.41421C6.84572 0.154284 9 1.04662 9 2.82843Z" fill="#18222B"/></svg>',
     top: '<svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.82568 5.99594L9 0L3.71547e-07 -3.93402e-07L3.17432 5.99593C3.73773 7.06016 5.26227 7.06016 5.82568 5.99594Z" fill="#18222B"/></svg>',
     right: '<svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.50406 3.67432L7.5 0.5V9.5L1.50407 6.32568C0.439841 5.76227 0.439839 4.23773 1.50406 3.67432Z" fill="#18222B"/></svg>',
     bottom: '<svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.32568 1.50406L9.5 7.5L0.5 7.5L3.67432 1.50407C4.23773 0.439841 5.76227 0.43984 6.32568 1.50406Z" fill="#18222B"/></svg>',
     left: '<svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.99594 3.67432L0 0.499999L-7.86805e-07 9.5L5.99593 6.32568C7.06016 5.76227 7.06016 4.23773 5.99594 3.67432Z" fill="#18222B"/></svg>',
};

export const TooltipHover = (props) => {
     const { children, title, description, icon, iconClose, placement, maxWidthContent, className,...rest } = props;
     const [placementCurrent, setPlacementCurrent] = useState(placement ? placement : 'top')

     const ref = useRef(null);

     const onClickHidden = () => {
          if (ref && ref.current) {
               ref.current._tippy.hide();
          }
     };

     const ContentTooltip = () => {
          return (
               <div className="badge-tooltip-wrapper relative">
                    {title && (
                         <div className="badge-tooltip-head d-flex">
                              {icon && <CIcon icon={icon} className="icon" style={{ '--ci-color': 'white' }} />}
                              <span className={`${iconClose ? 'hasIconClose' : ''}`}>{title}</span>
                         </div>
                    )}

                    {description && (
                         <div className="badge-tooltip-des">
                              <span>{description}</span>
                         </div>
                    )}

                    {iconClose && (
                         <div className="position-absolute badge-tooltip-icon-clear" onClick={() => onClickHidden()}>
                              {<CIcon icon={iconClose} className="icon" style={{width: '12px'}} />}
                         </div>
                    )}
               </div>
          );
     };
     return (
          <Tippy
               content={<>{ContentTooltip()}</>}
               offset={[2, 4]}
               placement={placementCurrent ? placementCurrent : 'top'}
               arrow={positionPath[placementCurrent ? placementCurrent : "top"]}
               onMount={(instance) => {
                    setPlacementCurrent(instance?.popperInstance?.state?.placement)
               }}
               className={`custom-tooltip-hover badge ${placementCurrent ? placementCurrent : 'top'} ${className ? className : ''}`}
               interactive={true}
               ref={ref}
               maxWidth={maxWidthContent ? maxWidthContent : 200}
               {...rest}
          >
               {children}
          </Tippy>
     );
};
