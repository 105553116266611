import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CButton, CModal, CModalBody } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import classNames from 'classnames';

import { setShowComparePlansPopup } from '../../../actions/subscriber';
import { roundTo2DecimalPlaces, numberWithCommas } from '../../../utils';

const unlimited = <i className="fal fa-infinity"></i>;

const ComparePlansPopup = () => {
     const dispatch = useDispatch();
     const showComparePlansPopup = useSelector(state => state.subscriber.showComparePlansPopup);
     const packages = useSelector(state => state.subscriber.packages);
     const currentPackageId = useSelector(state => state.subscriber.activeAccount.packageId);
     const isEnterprise = packages.filter(packageData => (currentPackageId === packageData.id && (['Pro', 'Business', 'Enterprise'].includes(packageData.friendlyName)))).length > 0;

     const onCloseModal = () => {
          dispatch(setShowComparePlansPopup(false));
     }

     const handleBodyScroll = () => {
          if (showComparePlansPopup) {
               document.body.classList.add('no-scroll');
          } else {
               document.body.classList.remove('no-scroll');
          }
     }

     useEffect(handleBodyScroll, [showComparePlansPopup]);

     return (
          <CModal className="full-screen-wrapper" visible={showComparePlansPopup} onClose={onCloseModal} portal={false}>
               <CModalBody>
                    <CButton
                         className="btn-back"
                         onClick={onCloseModal}
                    >
                         <i className="fal fa-angle-left"></i>
                         Back
                    </CButton>
                    <CIcon
                         icon="logo-dark"
                         height={34}
                    />
                    <h3>Plans & Pricing</h3>
                    <h6>Compare plans below. Paid plans include 15 days free when you upgrade for the first time!</h6>
                    <div className="list-package">
                         {
                              packages.map((packageData, packageIndex) => {
                                   if ((packageData.pricePerMonth === 0 && isEnterprise) || (packageData.friendlyName === 'Enterprise' && !isEnterprise)) {
                                        return false;
                                   }

                                   const {
                                        id,
                                        friendlyName,
                                        pricePerMonth,
                                        pricePerYear,
                                        numberOfListeners,
                                        numberOfPremium,
                                        numberOfDomains,
                                        numberOfCusDatalayerVariables,
                                        numberOfCusVariablesRules,
                                        numberOfCusBrowserVariables,
                                        numberOfCusBrowserRules,
                                        incrementalCostOfEvents,
                                        numberOfEventsPerMonth,
                                        numberOfUsers,
                                        eventReporting,
                                        numberOfEventMonitor,
                                        // numberOfSaveReportingValues,
                                        // gtagIntegration,
                                        // tealiumIntegration,
                                        // adobeIntegration
                                   } = packageData;

                                   const divClasses = classNames(
                                        'package-item',
                                        { 'current-package': currentPackageId === id },
                                        { 'hot-package': (currentPackageId !== id) && (packageIndex === 3) }
                                   );
                                   const monthlyCostIfPayAnnual = roundTo2DecimalPlaces(pricePerYear / 12);

                                   return (
                                        <div className={divClasses} key={id}>
                                             {currentPackageId === id && <label className="current-label package-label">Current</label>}
                                             {
                                                  (currentPackageId === id) && (
                                                       <label className='current-label package-label'>
                                                            Current
                                                       </label>
                                                  )
                                             }
                                             {
                                                  (currentPackageId !== id && packageIndex === 3) && (
                                                       <label className='hot-package-label package-label'>
                                                            Most Popular
                                                       </label>
                                                  )
                                             }
                                             <h5>{friendlyName}</h5>
                                             <h3>${monthlyCostIfPayAnnual.toLocaleString('en-US')}</h3>
                                             {
                                                  pricePerMonth === 0 ? (
                                                       <p>Free forever. Something about this plan.</p>
                                                  ) : (
                                                       <p>per month, billed annually, or ${pricePerMonth.toLocaleString('en-US')} month-to-month.</p>
                                                  )
                                             }
                                             <ul>
                                                  <li className="package-category">
                                                       <span>Usage</span>
                                                  </li>
                                                  <li>
                                                       <span>Total Listeners</span>
                                                       <span>{(numberOfListeners === -1) ? unlimited : numberOfListeners}</span>
                                                  </li>
                                                  <li>
                                                       <span>Premium Listeners/Features</span>
                                                       <span>{(numberOfPremium === -1) ? unlimited : numberOfPremium}</span>
                                                  </li>
                                                  <li>
                                                       <span>Website Domains</span>
                                                       <span>{(numberOfDomains === -1) ? unlimited : numberOfDomains}</span>
                                                  </li>
                                                  <li>
                                                       <span>Account Users</span>
                                                       <span>{(numberOfUsers === -1) ? unlimited : numberOfUsers}</span>
                                                  </li>
                                                  <li>
                                                       <span>Monthly Events</span>
                                                       <span>{(numberOfEventsPerMonth === -1) ? unlimited : numberWithCommas(numberOfEventsPerMonth)}</span>
                                                  </li>
                                                  <li>
                                                       <span>Extra Events Price</span>
                                                       <span>${incrementalCostOfEvents}</span>
                                                  </li>

                                                  <li className="package-category">
                                                       <span>Customization</span>
                                                  </li>
                                                  <li>
                                                       <span>Custom Data Layer Variables</span>
                                                       <span>{(numberOfCusDatalayerVariables === -1) ? unlimited : numberOfCusDatalayerVariables}</span>
                                                  </li>
                                                  <li>
                                                       <span>Custom Variable Rules</span>
                                                       <span>{(numberOfCusVariablesRules === -1) ? unlimited : numberOfCusVariablesRules}</span>
                                                  </li>
                                                  <li>
                                                       <span>Custom Browser Storage Variables</span>
                                                       <span>{(numberOfCusBrowserVariables === -1) ? unlimited : numberOfCusBrowserVariables}</span>
                                                  </li>
                                                  <li>
                                                       <span>Browser Storage Rules</span>
                                                       <span>{(numberOfCusBrowserRules === -1) ? unlimited : numberOfCusBrowserRules}</span>
                                                  </li>

                                                  <li className="package-category">
                                                       <span>Monitoring & Reporting</span>
                                                  </li>
                                                  <li>
                                                       <span>Event Monitoring (Listeners)</span>
                                                       <span>{(numberOfEventMonitor === 0) ? 'No' : ((numberOfEventMonitor === -1) ? unlimited : numberOfEventMonitor)}</span>
                                                  </li>
                                                  <li>
                                                       <span>Event Reporting</span>
                                                       <span>{eventReporting ? 'Yes' : 'No'}</span>
                                                  </li>
                                                  {/* <li>
                                                       <span>Save Reporting Values</span>
                                                       <span>{numberOfSaveReportingValues ? `${numberOfSaveReportingValues}/Listener` : 'No'}</span>
                                                  </li> */}

                                                  {/* <li>
                                                       <span>Google Tag Manager Integration</span>
                                                       <span>{gtagIntegration ? 'Yes' : 'No'}</span>
                                                  </li>
                                                  <li>
                                                       <span>Tealium Integration</span>
                                                       <span>{tealiumIntegration ? 'Yes' : 'No'}</span>
                                                  </li>
                                                  <li>
                                                       <span>Adobe Integration</span>
                                                       <span>{adobeIntegration ? 'Yes' : 'No'}</span>
                                                  </li> */}
                                             </ul>
                                        </div>
                                   )
                              })
                         }
                    </div>
               </CModalBody>
          </CModal>
     )
}

export default ComparePlansPopup
