import { SF_JOB_STATUS, RULE_SYNCING_STATUS } from "../constants";

export const getListJobs = (payload) => {
     return {
          data: payload.data,
          total: payload.total,
          itemsPerPage: payload.itemsPerPage,
          activePage: payload.activePage,
     };
}

export const updateJobs = (state, payload) => {
     const { SFJobs } = state;
     const jobs = SFJobs.data;
     const newJobs = jobs.map((job) => {
          const jobUpdate = payload.find((_item) => _item.id === job.id);
          if (jobUpdate) {
               job.retry = jobUpdate.retry;
               job.status = jobUpdate.status;
               job.totalSyncCount = jobUpdate.totalSyncCount;
               job.syncFailedCount = jobUpdate.syncFailedCount;
               job.syncCompletedCount = jobUpdate.syncCompletedCount;
               job.syncNewCount = jobUpdate.syncNewCount >= 0 ? jobUpdate.syncNewCount : 'Counting...';
               job.syncModifiedCount = jobUpdate.syncModifiedCount >= 0 ? jobUpdate.syncModifiedCount : 'Counting...';
               job.relationStatus = jobUpdate.relationStatus;
          }
          return job;
     })
     return {
          data: newJobs,
          total: SFJobs.total,
          itemsPerPage: SFJobs.itemsPerPage,
          activePage: SFJobs.activePage,
     }
}

export const updateSaleForcePaging = (state, payload) => {
     const total = payload.salesforceRuleTotal;
     const active = payload.salesforceRuleActivePage;
     const itemsPerPage = payload.itemsPerPage;
     return {
          salesforceRuleTotal: total ? total : state.salesforceRuleTotal,
          salesforceRuleActivePage: active ? active : state.salesforceRuleActivePage,
          itemsPerPage: itemsPerPage ? itemsPerPage : state.itemsPerPage,
     };
};

export const updateProgressRule = (state, payload) => {
     const SFjobActive = state;

     if (!SFjobActive || (SFjobActive && SFjobActive.ruleId === payload.ruleId)) {
          return payload;
     } else {
          return SFjobActive;
     }
};

export const updateRuleData = (state, payload) => {
     const { salesforceRules } = state;
     const job = payload[0];

     const getTextStatus = (status) => {
          if (status === SF_JOB_STATUS.WAITING) return RULE_SYNCING_STATUS.WAITING;
          if (status === SF_JOB_STATUS.RUNNING) return RULE_SYNCING_STATUS.SYNCING;

          return RULE_SYNCING_STATUS.FINISHED;
     };

     const newRules = salesforceRules.reduce((acc, rule) => {
          const ruleUpdate = payload.find((_item) => _item.ruleId === rule.id);

          if (ruleUpdate) {
               rule.statusSyncing = getTextStatus(job.status);

               if (!rule?.isReady) {
                    rule.isReady = true;
               }
               
               if ([SF_JOB_STATUS.CANCEL, SF_JOB_STATUS.FINISHED, SF_JOB_STATUS.HOLD, SF_JOB_STATUS.FAILED].includes(job.status)) {
                    rule.status = true;
               }
          }

          acc.push(rule);
          return acc;
     }, []);

     return { data: newRules };
}