import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import CIcon from '@coreui/icons-react';
import { API_CLIENT_REPORTING_CHART, BQ_JOB_STATUS, DEFAULT_DATE_FORMAT, REPORT_DATA_TYPES } from '../../../../../constants';
import { EventExplorerContext } from '../EventExplorer';
import { CustomDropdown, CustomDropdownItem } from '../../../../general/customDropdown';
import { useSelector } from 'react-redux';
import { callTokenApiCancel } from '../../../../../apiCaller';
import { convertReportDuration } from '../../../../../utils';
import LineChart from './LineChart';
import SelectSegment from './SelectSegment';
import { useDebouncedCallback } from '../../../../../helpers/customHooks';
import { iconLegendMetric, iconLegendTritangleMetric } from '../../../../../assets/icons/logo-negative';

function ChartReport() {
     const {
          reportName,
          scoped,
          reportType,
          conversionName,
          conversionNameMetric,
          dateRangeConfig,
          showData,
          metricsStepForm,
          filterDimensions,
          listSegmentReportApply,
          listChartSegmentApply,
     } = useContext(EventExplorerContext);
     const { metrics, dimensions } = reportType ? REPORT_DATA_TYPES[reportName][scoped] : REPORT_DATA_TYPES[reportName];
     const metricsConversionName = [...metrics, ...conversionNameMetric, ...metricsStepForm];
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const dateRangeReports = useSelector((state) => state.subscriber.dateRangeReports);
     const reloadReportChart = useSelector((state) => state.subscriber.reloadReportChart);
     const segments = useMemo(() => listSegmentReportApply.filter((seg) => seg.apply && seg.queryStatus === BQ_JOB_STATUS.DONE).sort((a, b) => {
          if (a.isCustom !== b.isCustom) {
               return a.isCustom ? 1 : -1;
          }
          return new Date(a.orderBy) - new Date(b.orderBy)
     }), [listSegmentReportApply]);
     const [chartSegment, setChartSegment] = useState(
          listChartSegmentApply.length > 0 ? listChartSegmentApply : segments.slice(0, 1).map((item) => { return { ...item, applyChart: true } })
     );

     useEffect(() => {
          if (chartSegment.length === 0) {
               setChartSegment(
                    segments.slice(0, 1).map((item) => {
                         return { ...item, applyChart: true };
                    })
               );
          }

          if (segments.length === 0) {
               setChartSegment([]);
          }
     }, [segments]) // eslint-disable-line

     const refJobId = useRef('');
     const timeCallApi = useRef(1500);
     const [isLoadingFetch, setIsLoadingFetch] = useState(false);
     const [chartIsCancel, setChartIsCancel] = useState(false); // eslint-disable-line

     const handleOrderCol = (arr, arrFilter) => {
          const newArr = [];
          arr.forEach((item) => {
               if (arrFilter.some((el) => el.value === item.key)) {
                    newArr.push(item.key);
               }
          });
          return newArr;
     };
     const newDimensions = handleOrderCol(dimensions, filterDimensions);
     const LineType = 'line';
     const arrColor = ['#FFC107', '#FFC107', '#6B7280', '#6B7280'];
     const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
     const arrIcon = [iconLegendMetric, iconLegendTritangleMetric]
     const arrTypeMarker = ['circle', 'triangle']
     const [dateType, setDateType] = useState('DAY');
     const [listMetricSelected, setListMetricSelected] = useState(
          metricsConversionName.length > 1
               ? getMetricSelected([metricsConversionName[0], metricsConversionName[1]])
               : getMetricSelected([metricsConversionName[0]])
     );
     const initialData = {
          series: [],
          vertical: [
               {
                    "type": "line",
                    "data": [0],
                    "name": "",
                    "color": "",
                    "axis": ""
               }
          ],
          categories: [],
     };

     const [chartData, setChartData] = useState(initialData);

     const [segmentChartData, setSegmentChartData] = useState({});

     const [labelDate, setLabelDate] = useState([]);
     const [labelDateCompare, setLabelDateCompare] = useState([]);

     const [loading, setLoading] = useState(true);

     // function getUnusedColor(arrColor, listMetricSelected) {
     //      for (const color of arrColor) {
     //           const colorExists = listMetricSelected.some((metric) => metric.color === color);
     //           if (!colorExists) {
     //                return color;
     //           }
     //      }
     //      return null;
     // }

     function getUnusedColor(arrColor, listMetricSelected) {
          for (const color of arrColor) {
               // const colorExists = listMetricSelected.some((metric) => metric.color === color);
               // if (!colorExists) {
               return color;
               // }
          }
          return null;
     }

     function getUnusedIcon(arrIcon, listMetricSelected) {
          for (const icon of arrIcon) {
               const iconExists = listMetricSelected.some((metric) => metric.icon === icon);
               if (!iconExists) {
                    return icon
               }
          }
          return null;
     }

     function getUnusedTypeMarker(arrTypeMarker, listMetricSelected) {
          for (const type of arrTypeMarker) {
               const typeExists = listMetricSelected.some((metric) => metric.typeMarker === type);
               if (!typeExists) {
                    return type
               }
          }
          return null;
     }

     function getKeyFromLabel(label, arr) {
          const match = arr.find((item) => item.label === label);
          return match ? match.key : null;
     }

     function transSeries(inputData) {
          const transformedData = [];
          for (const key in inputData) {
               if (key !== 'labels' && !key.includes('compare')) {
                    transformedData.push({
                         type: LineType,
                         data: inputData[key],
                         name: listMetricSelected.find((i) => i.key === key).label || key,
                         color: listMetricSelected.find((i) => i.key === key).color || '',
                         axis: listMetricSelected.find((i) => i.key === key).label || key,
                         icon: listMetricSelected.find((i) => i.key === key).icon || key,
                         typeMarker: listMetricSelected.find((i) => i.key === key).typeMarker || key,
                    });
               }

               if (key.includes('compare')) {
                    const value = key.replace('compare', '');
                    transformedData.push({
                         type: LineType,
                         data: inputData[key],
                         name: listMetricSelected.find((i) => i.key === key.replace('compare', '')).label || key,
                         color: arrColor.at(listMetricSelected.map((item) => item.key).indexOf(value) + 2),
                         axis: listMetricSelected.find((i) => i.key === key.replace('compare', '')).label || key,
                         icon: listMetricSelected.find((i) => i.key === key.replace('compare', '')).icon || key,
                         typeMarker: listMetricSelected.find((i) => i.key === key.replace('compare', '')).typeMarker || key,
                    });
               }
          }

          return transformedData;
     }

     function transVertical(inputData) {
          const transformedData = [];
          for (const key in inputData) {
               const compareData = inputData[`compare${key}`] ? inputData[`compare${key}`] : [];
               if (key !== 'labels' && !key.includes('compare')) {
                    transformedData.push({
                         type: LineType,
                         data: [...inputData[key], ...compareData],
                         name: listMetricSelected.find((i) => i.key === key).label || key,
                         // color: listMetricSelected.find((i) => i.key === key).color || '',
                         color: '#4B5563',
                         axis: listMetricSelected.find((i) => i.key === key).label || key,
                    });
               }
          }

          return transformedData;
     }

     const checkEmptyValue = (inputData) => {
          let countEmpty = 0;
          let inputLength = 0;
          for (const key in inputData) {
               if (key !== 'labels') {
                    inputLength++;
                    if (inputData[key].every((item) => item === 0)) countEmpty++;
               }
          }

          return countEmpty === inputLength;
     };

     function getMetricLabel(data) {
          return data.map((i) => {
               return i.label;
          });
     }

     function getMetricKey(data) {
          return data.map((i) => {
               return i.key;
          });
     }

     function getMetricSelected(data) {
          return data.map((i, index) => {
               return { key: i.key, label: i.label, color: arrColor[index] };
          });
     }

     function formatDateRangeByMonth(inputValue, dateRangeReports, compare) {
          const date = dayjs(inputValue, 'YYYY-M');

          const selectionStartDate = !compare
               ? dayjs(dateRangeReports.selection.startDate.toDate())
               : dayjs(dateRangeReports.compare.startDate.toDate());
          const selectionEndDate = !compare ? dayjs(dateRangeReports.selection.endDate.toDate()) : dayjs(dateRangeReports.compare.endDate.toDate());

          const startIsSameMonth = selectionStartDate.isSame(date, 'month');
          const endIsSameMonth = selectionEndDate.isSame(date, 'month');

          const startDay = startIsSameMonth ? selectionStartDate.format('D') : '01';
          const endDay = endIsSameMonth ? selectionEndDate.format('D') : dayjs(date).daysInMonth();

          return `${startDay} ${date.format('MMM')}, ${date.format('YYYY')} - ${endDay} ${date.format('MMM')}, ${date.format('YYYY')}`;
     }

     function getStartAndEndOfWeek(yearWeekValue) {
          const [year, week] = yearWeekValue.split('-');
          const startOfWeek = dayjs()
               .year(year)
               .month(0)
               .date(1)
               .add((week - 1) * 7, 'day')
               .day(1); // Start from Monday
          const endOfWeek = startOfWeek.add(6, 'day'); // End at Sunday
          return { startOfWeek, endOfWeek };
     }

     function formatDateRangeByYearWeek(yearWeekValue, dateRangeReports, compare) {
          const { startOfWeek, endOfWeek } = getStartAndEndOfWeek(yearWeekValue);
          const selectionStartDate = !compare ? dayjs(dateRangeReports.selection.startDate) : dayjs(dateRangeReports.compare.startDate);
          const selectionEndDate = !compare ? dayjs(dateRangeReports.selection.endDate) : dayjs(dateRangeReports.compare.endDate);

          let formattedStartDate = startOfWeek.isAfter(selectionStartDate) ? startOfWeek : selectionStartDate;
          let formattedEndDate = endOfWeek.isBefore(selectionEndDate) ? endOfWeek : selectionEndDate;

          return `${formattedStartDate.format('MMM D, YYYY')} - ${formattedEndDate.format('MMM D, YYYY')}`;
     }

     // Function to format date according to dateType
     const formatDateTitleTooltip = (date, dateType, compare = false, day = '') => {
          switch (dateType) {
               case 'DAY':
                    return `${day instanceof Date ? daysOfWeek[day.getDay()] : ''} ${dayjs(date).format('DD MMM, YY')}`;
               case 'WEEK':
                    return formatDateRangeByYearWeek(date, dateRangeReports, compare);
               case 'MONTH':
                    return formatDateRangeByMonth(date, dateRangeReports, compare);
               default:
                    return date;
          }
     };

     function formatNumberWithCommas(number) {
          return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
     }

     function isNumber(value) {
          return !isNaN(Number(value));
     }

     function formatNumber(value) {
          if (!isNumber(value)) {
               return String(value);
          } else {
               return formatNumberWithCommas(Number(value));
          }
     }

     // function countMonthsBetweenDates(startDate, endDate) {
     //      const start = dayjs(startDate);
     //      const end = dayjs(endDate);

     //      const startYear = start.year();
     //      const endYear = end.year();
     //      const startMonth = start.month();
     //      const endMonth = end.month();

     //      const monthsDifference = (endYear - startYear) * 12 + (endMonth - startMonth) + 1;
     //      return monthsDifference;
     // }

     // const countMonth = countMonthsBetweenDates(dateRangeReports.startDate, dateRangeReports.endDate);

     function filterAndRemoveItems(metricCurrent, metrics) {
          const keysInMetrics = metrics.map((item) => item.key);
          const filteredMetricCurrent = metricCurrent.filter((item) => keysInMetrics.includes(item.key));
          return filteredMetricCurrent;
     }
     function checkKeysExist(metricCurrent, metrics) {
          for (const item1 of metricCurrent) {
               if (!metrics.some((item2) => item2.key === item1.key)) {
                    return false;
               }
          }
          return true;
     }

     const newDateRange = {
          selection: {
               startDate: dateRangeReports.selection.startDate.format(DEFAULT_DATE_FORMAT),
               endDate: dateRangeReports.selection.endDate.format(DEFAULT_DATE_FORMAT),
          },
     };

     if (dateRangeReports.compare) {
          newDateRange.compare = {
               startDate: dateRangeReports.compare.startDate.format(DEFAULT_DATE_FORMAT),
               endDate: dateRangeReports.compare.endDate.format(DEFAULT_DATE_FORMAT),
          };
     }

     if (dateRangeConfig.dateScope) {
          newDateRange.dateScope = dateRangeConfig.dateScope;
     }

     let bodyData = {
          reportName,
          accountId: activeAccount.id,
          dateRanges: newDateRange,
          dateType: dateType,
          metrics: getMetricKey(listMetricSelected),
          dimensions: newDimensions,
          scoped: scoped,
          conversionName: conversionName.filter((item) => item.label.includes('Conv')),
          eCommerceName: conversionName.filter((item) => item.label.includes('Ecommerce')),
          isCompare: dateRangeConfig.comparePeriod,
          reportType,
          segments: chartSegment,
     };

     function fetchDataChart() {
          refJobId.current = '';
          setLoading(true);
          callTokenApiCancel(API_CLIENT_REPORTING_CHART, 'POST', bodyData).then((response) => {
               if (response && response.status === 200) {
                    const { data, labels, labelCompare, jobId, message } = response.data;
                    if (message) {
                         if (jobId) {
                              refJobId.current = jobId;
                         }
                         setIsLoadingFetch(true);
                    } else {
                         setIsLoadingFetch(false);
                         if (chartSegment.length > 0) {
                              let chartValue = {};
                              const segmentData = data;
                              chartSegment.forEach((item) => {
                                   const data = segmentData[item.name];
                                   const returnData = {};
                                   if (dateType === 'DAY') {
                                        returnData.categories = data.labels.map((item) => new Date(item));

                                        // returnData.categories = data.labels.map((item) => {
                                        //      const day = new Date(item).getDay()
                                        //      return daysOfWeek[day]
                                        // });
                                   } else {
                                        returnData.categories = data.labels;
                                   }

                                   returnData.series = transSeries(data);
                                   returnData.vertical = transVertical(data);

                                   if (!checkEmptyValue(data)) {
                                        chartValue = { ...chartValue, [item.name]: returnData };
                                   }
                              });
                              setSegmentChartData(chartValue);
                         } else {
                              const chartValue = {};
                              if (dateType === 'DAY') {
                                   chartValue.categories = data.labels.map((item) => new Date(item));

                                   // chartValue.categories = data.labels.map((item) => {
                                   //      const day = new Date(item).getDay()
                                   //      return daysOfWeek[day]
                                   // });
                              } else {
                                   chartValue.categories = data.labels;
                              }
                              chartValue.series = transSeries(data);
                              chartValue.vertical = transVertical(data);

                              setChartData({ ...chartData, ...chartValue });
                         }
                         setLabelDateCompare(labelCompare);
                         setLabelDate(labels);
                         timeCallApi.current = 100;
                    }
               }
               if (response && response.status) {
                    setLoading(false);
               }
          });
     }

     useEffect(() => {
          timeCallApi.current = 1500;
     }, [scoped])

     //#region add debounce fetchData after time 0.1s
     const handleFetchData = useCallback(() => {
          fetchDataChart();
          // Replace with your actual API call
     }, [dateType, listMetricSelected, dateRangeReports, dateRangeConfig, scoped, chartSegment]); //eslint-disable-line

     const [debouncedFetchData, cancelDebounce] = useDebouncedCallback(handleFetchData, timeCallApi.current);
     
     useEffect(() => {
          if (!checkKeysExist(listMetricSelected, metricsConversionName)) {
               setListMetricSelected(filterAndRemoveItems(listMetricSelected, metricsConversionName));
          }
          if(listMetricSelected.length > 0) {
               let newListMetricSelected = listMetricSelected.map(metric => {
                    return {
                         key: getKeyFromLabel(metric?.label, metricsConversionName), 
                         label: metric?.label, 
                         color: getUnusedColor(arrColor, listMetricSelected), 
                         icon: getUnusedIcon(arrIcon, listMetricSelected),
                         typeMarker: getUnusedTypeMarker(arrTypeMarker, listMetricSelected)
                    }
               })
               setListMetricSelected(newListMetricSelected);
          }
          //eslint-disable-next-line
     }, [scoped]);

     useEffect(() => {
          debouncedFetchData();

          // Cleanup to cancel the debounce when the component unmounts
          return () => {
               cancelDebounce();
          };
     }, [debouncedFetchData, cancelDebounce, dateType, listMetricSelected, dateRangeReports, dateRangeConfig, scoped, chartSegment]); //eslint-disable-line
     //# end-region add debounce fetchData after time 0.1s

     useEffect(() => {
          if (reloadReportChart && reloadReportChart.jobId === refJobId.current) {
               const { status, tableName, comparisonTableName, segmentTables, comparisonSegmentTables } = reloadReportChart;

               if (status === BQ_JOB_STATUS.DONE) {
                    if (reloadReportChart.reportName === reportName) {
                         if (tableName) {
                              bodyData.tableName = tableName;
                         }
                         if (comparisonTableName) {
                              bodyData.comparisonTableName = comparisonTableName;
                         }
                         if (segmentTables) {
                              bodyData.segmentTables = segmentTables;
                         }
                         if (comparisonSegmentTables) {
                              bodyData.comparisonSegmentTables = comparisonSegmentTables;
                         }
                    }
                    fetchDataChart();
               } else {
                    if (status === BQ_JOB_STATUS.CANCELED) {
                         setChartIsCancel(true);
                    }
                    setIsLoadingFetch(false);
               }
          }
     }, [reloadReportChart.refreshRequestChart]); // eslint-disable-line

     const handleDayType = (type) => {
          if (!showData) {
               return;
          }
          setDateType(type);
     };

     const deleteMetricCurrent = (label) => {
          if (!showData) {
               return;
          }
          setListMetricSelected(listMetricSelected.filter((item) => item.label !== label));
     };

     const handleAddListCurrentMetric = (item) => {
          if (!showData) {
               return;
          }
          setListMetricSelected((prev) => [
               ...prev,
               { 
                    key: getKeyFromLabel(item, metricsConversionName), 
                    label: item, 
                    color: getUnusedColor(arrColor, listMetricSelected), 
                    icon: getUnusedIcon(arrIcon, listMetricSelected),
                    typeMarker: getUnusedTypeMarker(arrTypeMarker, listMetricSelected)
               },
          ]);
     };

     const loadingPanel = (
          <div className="k-loading-mask">
               <span className="k-loading-text">Loading</span>
               <div className="k-loading-image"></div>
               <div className="k-loading-color"></div>
          </div>
     );

     // const SharedTooltip = (props) => {
     //      const { points } = props;
     //      const compareNumber = dateRangeConfig.comparePeriod ? listMetricSelected.length : 0;
     //      let labelText;
     //      let labelCompare;
     //      const formatText = labelDate.at(points[0].point.categoryIx) ? false : true;
     //      switch (dateType) {
     //           case 'DAY':
     //                labelText = labelDate.at(points[0].point.categoryIx)
     //                     ? dayjs(labelDate.at(points[0].point.categoryIx))
     //                     : dayjs(labelDateCompare.at(points[0].point.categoryIx));
     //                if (dateRangeConfig.comparePeriod)
     //                     labelCompare = points.at(compareNumber) ? dayjs(labelDateCompare.at(points.at(compareNumber).point.categoryIx)) : null;
     //                break;
     //           case 'WEEK':
     //           case 'MONTH':
     //                labelText = labelDate.at(points[0].point.categoryIx)
     //                     ? labelDate.at(points[0].point.categoryIx)
     //                     : labelDateCompare.at(points[0].point.categoryIx);
     //                if (dateRangeConfig.comparePeriod)
     //                     labelCompare = points.at(compareNumber) ? labelDateCompare.at(points.at(compareNumber).point.categoryIx) : null;
     //                break;
     //           default:
     //                break;
     //      }

     //      return (
     //           <div
     //                style={{
     //                     maxWidth: '300px',
     //                     padding: '10px',
     //                     background: 'white',
     //                     borderRadius: '10px',
     //                     boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
     //                }}
     //           >
     //                <strong style={{ width: 'max-content', display: 'inline-block' }}>
     //                     {formatDateTitleTooltip(labelText, dateType, formatText)}
     //                </strong>
     //                {points.map((point, i) => (
     //                     <div key={i}>
     //                          {i === compareNumber && dateRangeConfig.comparePeriod && (
     //                               <strong style={{ width: 'max-content', display: 'inline-block' }}>
     //                                    {formatDateTitleTooltip(labelCompare, dateType, true)}
     //                               </strong>
     //                          )}
     //                          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 15px', gap: '15px' }}>
     //                               <div style={{ position: 'relative', width: 'max-content' }}>
     //                                    <div>{point.series.name}</div>
     //                                    <i
     //                                         style={{
     //                                              position: 'absolute',
     //                                              width: '8px',
     //                                              height: '8px',
     //                                              background: point.series.color,
     //                                              top: '50%',
     //                                              left: '-15px',
     //                                              borderRadius: '50%',
     //                                              transform: 'translate(0,-50%)',
     //                                         }}
     //                                    />
     //                               </div>
     //                               <div>
     //                                    {point.series.name.includes('Rate') ? (
     //                                         `${formatNumber(point.value)}%`
     //                                    ) : (
     //                                         <>
     //                                              {(() => {
     //                                                   switch (point.series.name) {
     //                                                        case 'Avg Session Duration':
     //                                                             return <span>{convertReportDuration(point.value)}</span>;
     //                                                        case 'Session Duration':
     //                                                             return <span>{convertReportDuration(point.value)}</span>;
     //                                                        case 'Avg Visible Time on Page':
     //                                                             return <span>{convertReportDuration(point.value ? point.value : 0)}</span>;
     //                                                        case 'Avg Visible Time/Page':
     //                                                        case 'Avg Visible Time / Page':
     //                                                             return <span>{convertReportDuration(point.value)}</span>;
     //                                                        default:
     //                                                             return <span>{`${formatNumber(point.value)}`}</span>;
     //                                                   }
     //                                              })()}
     //                                         </>
     //                                    )}
     //                               </div>
     //                          </div>
     //                     </div>
     //                ))}
     //           </div>
     //      );
     // };

     const SharedTooltip = (props) => {
          const { points } = props;
          const compareNumber = dateRangeConfig.comparePeriod ? listMetricSelected.length : 0;
          let labelText;
          let labelCompare;
          const formatText = labelDate.at(points[0].point.categoryIx) ? false : true;
          switch (dateType) {
               case 'DAY':
                    labelText = labelDate.at(points[0].point.categoryIx)
                         ? dayjs(labelDate.at(points[0].point.categoryIx))
                         : dayjs(labelDateCompare.at(points[0].point.categoryIx));
                    if (dateRangeConfig.comparePeriod)
                         labelCompare = points.at(compareNumber) ? dayjs(labelDateCompare.at(points.at(compareNumber).point.categoryIx)) : null;
                    break;
               case 'WEEK':
               case 'MONTH':
                    labelText = labelDate.at(points[0].point.categoryIx)
                         ? labelDate.at(points[0].point.categoryIx)
                         : labelDateCompare.at(points[0].point.categoryIx);
                    if (dateRangeConfig.comparePeriod)
                         labelCompare = points.at(compareNumber) ? labelDateCompare.at(points.at(compareNumber).point.categoryIx) : null;
                    break;
               default:
                    break;
          }

          return (
               <div
                    style={{
                         maxWidth: '300px',
                         padding: '10px',
                         background: 'white',
                         borderRadius: '10px',
                         boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                    }}
               >
                    {
                         listMetricSelected.map((metric, index) => {
                              return (
                                   <div className={`${index === 0 ? 'mb-6' : ''}`} key={index}>
                                        <strong className='mb-2' style={{ width: 'max-content', display: 'inline-block' }}>
                                             {metric.label}
                                        </strong>
                                        {points.map((point, i) => {
                                             if (point.series.name === metric.label) {
                                                  let iconTag = <CIcon className='svg-custom' icon={point.series.icon} style={{ stroke: `${point.series.color}`, width: '19px', height: '19px' }} />;
                                                  return (
                                                       <div className='mb-1' key={i}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 15px', gap: '15px' }}>
                                                                 <div className='d-flex justify-content-between' style={{ position: 'relative', width: 'max-content' }}>
                                                                      {iconTag}
                                                                      <div className='ms-2 me-2'>{i === 0 || i === 1 ? formatDateTitleTooltip(labelText, dateType, formatText, point.category) : formatDateTitleTooltip(labelCompare, dateType, true, point.category)}</div>
                                                                      {/* <i
                                                                           style={{
                                                                                position: 'absolute',
                                                                                width: '8px',
                                                                                height: '8px',
                                                                                background: point.series.color,
                                                                                top: '50%',
                                                                                left: '-15px',
                                                                                borderRadius: '50%',
                                                                                transform: 'translate(0,-50%)',
                                                                           }}
                                                                      /> */}
                                                                 </div>
                                                                 <div>
                                                                      {point.series.name.includes('Rate') ? (
                                                                           `${formatNumber(point.value)}%`
                                                                      ) : (
                                                                           <>
                                                                                {(() => {
                                                                                     switch (point.series.name) {
                                                                                          case 'Avg Session Duration':
                                                                                               return <span>{convertReportDuration(point.value)}</span>;
                                                                                          case 'Session Duration':
                                                                                               return <span>{convertReportDuration(point.value)}</span>;
                                                                                          case 'Avg Visible Time on Page':
                                                                                               return <span>{convertReportDuration(point.value ? point.value : 0)}</span>;
                                                                                          case 'Avg Visible Time/Page':
                                                                                          case 'Avg Visible Time / Page':
                                                                                               return <span>{convertReportDuration(point.value)}</span>;
                                                                                          default:
                                                                                               return <span>{`${formatNumber(point.value)}`}</span>;
                                                                                     }
                                                                                })()}
                                                                           </>
                                                                      )}
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  )
                                             }
                                             return null;
                                        })}
                                   </div>
                              )
                         })
                    }
               </div>
          );
     };
     const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

     return (
          <div className="wrapperChartReport">
               <div className="headChart">
                    <div className="customMetricChart">
                         {segments.length > 0 && (
                              <SelectSegment chartSegment={chartSegment} setChartSegment={setChartSegment} segments={segments} />
                         )}
                         {listMetricSelected &&
                              listMetricSelected.map((item, index) => {
                                   return (
                                        <span key={item.label + index} className="customMetricText">
                                             {/* <i className="iconColorMetric" style={{ background: item.color }} /> */}
                                             <CIcon className="me-2 svg-custom" icon={item.icon} style={{ width: '20px', height: '20px', stroke: `${item.color}` }} />
                                             {item.label}
                                             <CIcon
                                                  name="iconClose"
                                                  width={10}
                                                  height={10}
                                                  onClick={() => {
                                                       deleteMetricCurrent(item.label);
                                                  }}
                                             />
                                        </span>
                                   )
                              })}
                         {metricsConversionName.length < 2 && listMetricSelected.length < 1 ? (
                              <CustomDropdown>
                                   <div className={`wrapperIconPlus${listMetricSelected.length > 1 ? ' customDisabled' : ''}`}>
                                        <CIcon name="iconPlus" className="iconPlus" width={13} height={13} />
                                   </div>
                                   <CustomDropdownItem
                                        search
                                        listDropdownItem={getMetricLabel(metricsConversionName)}
                                        itemActive={getMetricLabel(listMetricSelected)}
                                        handleClickListDropdownItem={handleAddListCurrentMetric}
                                   />
                              </CustomDropdown>
                         ) : (
                              metricsConversionName.length > 1 &&
                              listMetricSelected.length < 2 && (
                                   <CustomDropdown>
                                        <div className={`wrapperIconPlus${listMetricSelected.length > 1 ? ' customDisabled' : ''}`}>
                                             <CIcon name="iconPlus" className="iconPlus" width={13} height={13} />
                                        </div>
                                        <CustomDropdownItem
                                             search
                                             listDropdownItem={getMetricLabel(metricsConversionName)}
                                             itemActive={getMetricLabel(listMetricSelected)}
                                             handleClickListDropdownItem={handleAddListCurrentMetric}
                                        />
                                   </CustomDropdown>
                              )
                         )}
                    </div>
                    <div className="actionChart">
                         {/* <CustomDropdown>
                              <span className='dropDownIcon'>
                                   Export <CIcon name='cilArrowThickToBottom' width={16} height={16} />
                              </span>
                              <CustomDropdownItem listDropdownItem={['Excel', 'SVG', 'Another']} handleClickListDropdownItem={handleClick} />
                         </CustomDropdown> */}
                         {/* <span>
                              Export <CIcon name='cilArrowThickToBottom' width={16} height={16} />
                         </span> */}
                         <div className="chartDate">
                              <span className={dateType === 'DAY' ? 'active' : ''} onClick={() => handleDayType('DAY')}>
                                   Day
                              </span>
                              <span className={dateType === 'WEEK' ? 'active' : ''} onClick={() => handleDayType('WEEK')}>
                                   Week
                              </span>
                              <span className={dateType === 'MONTH' ? 'active' : ''} onClick={() => handleDayType('MONTH')}>
                                   Month
                              </span>
                         </div>
                    </div>
               </div>
               {dateRangeConfig.comparePeriod && (
                    <div className="compareChart">
                         <p>
                              <b>
                                   {`${dayjs(newDateRange.selection.startDate).format('MMM D, YYYY')} - ${dayjs(
                                        newDateRange.selection.endDate
                                   ).format('MMM D, YYYY')}`}
                              </b>{' '}
                              :{' '}
                              {listMetricSelected &&
                                   listMetricSelected.map((item, index) => {
                                        return (
                                             <span key={item.label + index}>
                                                  {/* <i className="iconColorMetric" style={{ background: item.color }} /> */}
                                                  <CIcon className="me-2 svg-custom" icon={item.icon} style={{ width: '20px', height: '20px', stroke: `${item.color}` }} />
                                                  {item.label}
                                             </span>
                                        )
                                   })}
                         </p>
                         <p>
                              <b>
                                   {`${dayjs(newDateRange.compare.startDate).format('MMM D, YYYY')} - ${dayjs(newDateRange.compare.endDate).format(
                                        'MMM D, YYYY'
                                   )}`}
                              </b>
                              :
                              {listMetricSelected &&
                                   listMetricSelected.map((item, index) => {
                                        return (
                                             <span key={item.label + index}>
                                                  {/* <i className="iconColorMetric" style={{ background: arrColor[index + 2] }} /> */}
                                                  <CIcon className="me-2 svg-custom" icon={item.icon} style={{ width: '20px', height: '20px', stroke: `${arrColor[index + 2]}` }} />
                                                  {item.label}
                                             </span>
                                        )
                                   })}
                         </p>
                    </div>
               )}

               <div className="containerChart" style={{ position: 'relative' }}>
                    {(loading || isLoadingFetch) && loadingPanel}
                    {loading || isLoadingFetch ? (
                         <LineChart data={initialData} sharedTooltipRender={sharedTooltipRender} />
                    ) : segments.length > 0 && chartSegment.length > 0 ? (
                         Object.keys(segmentChartData).length === 0 ? (
                              <div className="chart-no-data">
                                   <p>
                                        No data for this combination of segments, values, filters, and date range. <br /> Try editing the variables or
                                        settings or remove them.
                                   </p>
                              </div>
                         ) : (
                              <div className="chart-segment">
                                   {chartSegment.map((item, key) => {
                                        if (!segmentChartData[item.name]) return <React.Fragment key={key}> </React.Fragment>;
                                        return (
                                             <div
                                                  key={key}
                                                  className={`custom-segment-dropdown ${Object.keys(segmentChartData).length > 1 ? 'chart-segment-item-4' : 'chart-segment-item'
                                                       } mb-2`}
                                             >
                                                  <div className="rule-segment d-flex align-item-center">
                                                       <div className="rule-segment-item d-flex align-items-center">
                                                            <div className="mr-2 rule-segment-item-icon d-flex align-items-center">
                                                                 <CIcon
                                                                      name="iconChartPie"
                                                                      style={{ cursor: 'pointer', width: '16px', height: '16px', marginRight: '5px' }}
                                                                 />
                                                                 <span className="rule-segment-item-level">S{segments.findIndex(_data => _data.id === item.id) + 1}</span>
                                                            </div>
                                                            <div className="d-flex align-items-center rule-segment-item-info">
                                                                 <p className="mr-3 mb-0">{item.name}</p>
                                                            </div>
                                                       </div>
                                                  </div>
                                                  <LineChart
                                                       key={key}
                                                       data={segmentChartData[item.name] || chartData}
                                                       sharedTooltipRender={sharedTooltipRender}
                                                       segmentLength={Object.keys(segmentChartData).length}
                                                  />
                                             </div>
                                        );
                                   })}
                              </div>
                         )
                    ) : (
                         <LineChart data={chartData} sharedTooltipRender={sharedTooltipRender} />
                    )}
               </div>
          </div>
     );
}

export default memo(ChartReport);
