import { ZstdCodec } from 'zstd-codec';
import { Buffer } from 'buffer';
import { parseJson } from '.';

let zstdSimple;

const initializeZstd = async () => {
     if (!zstdSimple) {
          await new Promise((resolve, reject) => {
               ZstdCodec.run((zstd) => {
                    try {
                         zstdSimple = new zstd.Simple();
                         resolve();
                    } catch (err) {
                         reject(err);
                    }
               });
          });
     }
};

// Compress data function
export const compressData = async (data) => {
     await initializeZstd();
     const dataBuffer = Buffer.from(JSON.stringify(data));
     return Buffer.from(zstdSimple.compress(dataBuffer));

};

// Decompress data function
export const decompressData = async (compressedData) => {
     await initializeZstd();
     const decompressedData = zstdSimple.decompress(compressedData);
     return parseJson(Buffer.from(decompressedData).toString('utf8'));

};