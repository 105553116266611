import Pusher from 'pusher-js';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SFUpdateJobs, SFUpdateJobsConnection, SFUpdateProgressBigqueryTables, SFUpdateProgressHistoricalSync, SFUpdateRunningDailyNew, SFUpdateIsReadyRule, SFUpdateIsSyncing } from '../../actions/external';

const ThePusherExternal = () => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);

     const handlePusher = () => {
          if (activeAccount.id) {
               // process.env.REACT_APP_PUSHER_KEY
               const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
                    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
               });
               const channel = pusher.subscribe(`channel-${activeAccount.id}`);

               channel.bind('SFUpdateJobs', function (data) {
                    dispatch(SFUpdateJobs(data));
                    dispatch(SFUpdateJobsConnection(data));
               });
               channel.bind('SFUpdateProgressHistoricalSync', function (data) {
                    dispatch(SFUpdateProgressHistoricalSync(data));
               });
               channel.bind('SFUpdateProgressBigqueryTables', function (data) {
                    dispatch(SFUpdateProgressBigqueryTables(data));
               });
               channel.bind('SFUpdateRunningDailyNew', function (data) {
                    dispatch(SFUpdateRunningDailyNew(data));
               });
               channel.bind('SFUpdateIsReadyRule', function (data) {
                    dispatch(SFUpdateIsReadyRule(data));
               });
               channel.bind('SFUpdateIsSyncing', function (data) {
                    dispatch(SFUpdateIsSyncing(data));
               });

               return () => {
                    channel.unbind();
               };
          }
     };
     useEffect(handlePusher, [activeAccount.id]); // eslint-disable-line
     return <></>;
};

export default React.memo(ThePusherExternal);
