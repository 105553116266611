export const DayCheckExample = {
     args: {
          type: 'radio',
          button: { color: 'light', variant: 'outline' },
          name: 'btnradio',
          id: 'btnradioday',
          autoComplete: 'off',
          value: 'day',
          label: 'Day',
     },
};

export const WeekCheckExample = {
     args: {
          type: 'radio',
          button: { color: 'light', variant: 'outline' },
          name: 'btnradio',
          id: 'btnradioweek',
          autoComplete: 'off',
          label: 'Week',
          value: 'week',
     },
};

export const MonthCheckExample = {
     args: {
          type: 'radio',
          button: { color: 'light', variant: 'outline' },
          name: 'btnradio',
          id: 'btnradiomonth',
          autoComplete: 'off',
          label: 'Month',
          value: 'month',
     },
};

export const DayChecBarChart = {
     args: {
          type: 'radio',
          button: { color: 'light', variant: 'outline' },
          name: 'btnradiobarchart',
          id: 'daybarchart',
          autoComplete: 'off',
          label: 'Day',
          value: 'day',
     },
};

export const WeekCheckBarChart = {
     args: {
          type: 'radio',
          button: { color: 'light', variant: 'outline' },
          name: 'btnradiobarchart',
          id: 'weekbarchart',
          autoComplete: 'off',
          label: 'Week',
          value: 'week',
     },
};

export const MonthCheckBarChart = {
     args: {
          type: 'radio',
          button: { color: 'light', variant: 'outline' },
          name: 'btnradiobarchart',
          id: 'monthbarchart',
          autoComplete: 'off',
          label: 'Month',
          value: 'month',
     },
};

export const DayChecBarChart2 = {
     args: {
          type: 'radio',
          button: { color: 'light', variant: 'outline' },
          name: 'btnradiobarchart2',
          id: 'daybarchart2',
          autoComplete: 'off',
          label: 'Day',
          value: 'day',
     },
};

export const WeekCheckBarChart2 = {
     args: {
          type: 'radio',
          button: { color: 'light', variant: 'outline' },
          name: 'btnradiobarchart2',
          id: 'weekbarchart2',
          autoComplete: 'off',
          label: 'Week',
          value: 'week',
     },
};

export const MonthCheckBarChart2 = {
     args: {
          type: 'radio',
          button: { color: 'light', variant: 'outline' },
          name: 'btnradiobarchart2',
          id: 'monthbarchart2',
          autoComplete: 'off',
          label: 'Month',
          value: 'month',
     },
};


export const argBtnGroup = [{ ...DayCheckExample.args }, { ...WeekCheckExample.args }, { ...MonthCheckExample.args }];
export const argBtnGroupBarchart = [{ ...DayChecBarChart.args }, { ...WeekCheckBarChart.args }, { ...MonthCheckBarChart.args }];
export const argBtnGroupBarchart2 = [{ ...DayChecBarChart2.args }, { ...WeekCheckBarChart2.args }, { ...MonthCheckBarChart2.args }];

export const argsSmallKPICard = {
     argsbadge: {
          as: 'div',
          className: 'text-uppercase d-flex align-items-center badge-custom',
          shape: 'rounded-pill',
          textColor: 'primary',
     },
     argsBadgeKPI: {
          className: 'text-uppercase d-flex align-items-center',
          textColor: 'light',
     },
     argBadgeMetricAdd: {
          bgColor: 'white',
          className: 'badge-metric-add d-flex justify-content-center align-items-center',
     },
     argBadgeMetric: {
          as: 'div',
          color: 'white',
          className: 'd-flex align-items-center badge-metric me-2',
          shape: 'rounded-pill',
          textBgColor: 'white',
          textColor: 'dark',
     },
};

export const COMPONENTS_DASHBOARD = {
     PERFORMANCE_OVERVIEW: 'performance-overview',
     WEBENGAGEMENT_OVERVIEW: 'web-engagement-overview',
     REVEAL_OVERVIEW: 'reveal-overview',
     ECOMMERCE_OVERVIEW: 'ecommerce-overview',
     SALES_CRM: 'sales-CRM',
     SESSION_SOURCE_OVERVIEW: 'session-source-overview',
};

export const DASHBOARD_DATA_TYPES = {
     [COMPONENTS_DASHBOARD.PERFORMANCE_OVERVIEW]: [
          { key: 'engagedSessionCount', label: 'Engaged Sessions' },
          { key: 'userCount', label: 'Users' },
          { key: 'conversionCount', label: 'Web Conversions Event' },
          { key: 'conversionValue', label: 'Web Conversions Value' },
          { key: 'sessionDuration', label: 'Session Duration' },
     ],
     [COMPONENTS_DASHBOARD.WEBENGAGEMENT_OVERVIEW]: [
          { key: 'sessionEngagementRate', label: 'Session Engagement Rate' },
          { key: 'conversionRateAll', label: 'Web Conversion Rate' },
          { key: 'peopleRevealedRate', label: 'People Revealed Rate' },
          { key: 'bounceRate', label: 'Bounce Rate' },
          { key: 'conversionRatePrimary', label: 'Primary Conversions Rate' },
          {
               key: 'targetAccountsActive',
               label: 'Target Accounts Active',
          },
          {
               key: 'avgSessionDuration',
               label: 'Avg. Session Duration',
          },
          { key: 'conversionRateSecondary', label: 'Secondary Conversions Rate' },
     ],
     [COMPONENTS_DASHBOARD.REVEAL_OVERVIEW]: [
          {
               key: 'peopleRevealed',
               label: 'People Revealed',
          },
          {
               key: 'companyRevealed',
               label: 'Companies Revealed',
          },
          {
               key: 'targetAccountsRevealed',
               label: 'Target Accounts Revealed',
          },
          {
               key: 'peopleActive',
               label: 'People Active',
          },
          {
               key: 'companyActive',
               label: 'Companies Active',
          },
          {
               key: 'targetAccountsActive',
               label: 'Target Accounts Active',
          },
          {
               key: 'peopleActive',
               label: 'People Active',
          },
          {
               key: 'companyActive',
               label: 'Companies Active',
          },
          {
               key: 'targetAccountsActive',
               label: 'Target Accounts Active',
          },
     ],
     [COMPONENTS_DASHBOARD.ECOMMERCE_OVERVIEW]: [
          {
               key: 'productView',
               label: 'Product Views',
          },
          {
               key: 'addToCart',
               label: 'Add to Carts',
          },
          {
               key: 'beginCheckouts',
               label: 'Begin Checkouts',
          },
          {
               key: 'purchases',
               label: 'Purchases',
          },
          {
               key: 'revenue',
               label: 'Revenue',
          },
          {
               key: 'averageOrderValue',
               label: 'Average Order Value',
          },
     ],
     [COMPONENTS_DASHBOARD.SALES_CRM]: [
          {
               key: 'newLeads',
               label: 'New Leads',
          },
          {
               key: 'newContacts',
               label: 'New Contacts',
          },
          {
               key: 'newAccounts',
               label: 'New Accounts',
          },
          {
               key: 'newOpportunities',
               label: 'New Opportunities',
          },
          {
               key: 'newOpportunitiesValue',
               label: 'New Opportunities Value',
          },
     ],
     [COMPONENTS_DASHBOARD.SESSION_SOURCE_OVERVIEW]: [
          {
               key: 'saleConversions',
               label: 'Sales Conversions',
          },
          {
               key: 'primarySaleConversions',
               label: 'Primary Sales Conversions',
          },
          {
               key: 'secondarySaleConversions',
               label: 'Secondary Sales Conversions',
          },
          {
               key: 'saleConversionsValue',
               label: 'Sales Conversions Value',
          },
          {
               key: 'primarySaleConversionsValue',
               label: 'Primary Sales Conversions Value',
          },
          {
               key: 'secondarySaleConversionsValue',
               label: 'Secondary Sales Conversions Value',
          },
     ],
};

export const METRICS_DASHBOARD = [
     { key: 'engagedSessionCount', label: 'Engaged Sessions' },
     { key: 'userCount', label: 'Active Users' },
     { key: 'webConversion', label: 'Web Conversions' },
     { key: 'webConversionValue', label: 'Web Conversions Value' },
     { key: 'peopleCount', label: 'People' },
     { key: 'sessionDuration', label: 'Session Duration' },
     { key: 'sessionEngagementRate', label: 'Session Engagement Rate' },
     { key: 'conversionRateAll', label: 'Web Conversion Rate' },
     { key: 'conversionRatePrimary', label: 'Primary Conversion Rate' },
     { key: 'conversionRateSecondary', label: 'Secondary Conversion Rate' },
     { key: 'revealedCompany', label: 'Companies Reavealed' },
     { key: 'conversionCount', label: 'Web Conversion Event' },
     {
          key: 'peopleRevealedRate',
          label: 'People Revealed Rate',
     },
     {
          key: 'bounceRate',
          label: 'Bounce Rate',
     },
     {
          key: 'avgSessionDuration',
          label: 'Avg. Session Duration',
     },
     {
          key: 'peopleActive',
          label: 'People Active',
     },
     {
          key: 'peopleRevealed',
          label: 'People Revealed',
     },
     {
          key: 'companyRevealed',
          label: 'Companies Revealed',
     },
     {
          key: 'companyActive',
          label: 'Companies Active',
     },
     {
          key: 'productViews',
          label: 'Product Views',
     },
     {
          key: 'addToCarts',
          label: 'Add to Carts',
     },
     {
          key: 'beginCheckouts',
          label: 'Begin Checkouts',
     },
     {
          key: 'purchases',
          label: 'Purchases',
     },
     {
          key: 'revenue',
          label: 'Revenue',
     },
     {
          key: 'averageOrderValue',
          label: 'Average Order Value',
     },
     {
          key: 'targetAccountsActive',
          label: 'Target Accounts Active',
     },
     {
          key: 'targetAccountsRevealed',
          label: 'Target Accounts Revealed',
     },
     {
          key: 'newLeads',
          label: 'New Leads',
     },
     {
          key: 'newContacts',
          label: 'New Contacts',
     },
     {
          key: 'newAccounts',
          label: 'New Accounts',
     },
     {
          key: 'newOpportunities',
          label: 'New Opportunities',
     },
     {
          key: 'newOpportunitiesValue',
          label: 'New Opportunities Value',
     },
     {
          key: 'saleConversions',
          label: 'Sales Conversions',
     },
     {
          key: 'primarySaleConversions',
          label: 'Primary Sales Conversions',
     },
     {
          key: 'secondarySaleConversions',
          label: 'Secondary Sales Conversions',
     },
     {
          key: 'saleConversionsValue',
          label: 'Sales Conversions Value',
     },
     {
          key: 'primarySaleConversionsValue',
          label: 'Primary Sales Conversions Value',
     },
     {
          key: 'secondarySaleConversionsValue',
          label: 'Secondary Sales Conversions Value',
     },
     {
          key: 'activeUserCount',
          label: 'Active Users',
     },
     {
          key: 'newUserCount',
          label: 'New Users',
     },
     {
          key: 'sessionCount',
          label: 'Sessions',
     },
     {
          key: 'viewCount',
          label: 'Views',
     },
     {
          key: 'addToCartCount',
          label: 'Add to Cart',
     },
     {
          key: 'purchaseCount',
          label: 'Purchased',
     },
     {
          key: 'itemRevenue',
          label: 'Revenue',
     },
];

export const DASHBOARD_CHART_GROUP = {
     WEB_CONVERSION: 'Web Conversion',
     ACTIVE_USER: 'Active User',
     SALES_CONVERSION: 'Sales Conversion',
     SESSION_SOURCE: 'Session Source',
     ECOMMERCE_REPORT: 'ecommerce-report'
};

export const METRICS_DASHBOARD_CHART_GROUP = {
     [DASHBOARD_CHART_GROUP.WEB_CONVERSION]: ['primaryConversionCount', 'secondaryConversionCount'],
     [DASHBOARD_CHART_GROUP.ACTIVE_USER]: ['activeUserCount', 'newUserCount'],
     [DASHBOARD_CHART_GROUP.SESSION_SOURCE]: ["sessionCount", "activeUserCount", "newUserCount"],
     [DASHBOARD_CHART_GROUP.SALES_CONVERSION]: ["primaryConversionCount", "secondaryConversionCount"],
     [DASHBOARD_CHART_GROUP.ECOMMERCE_REPORT]: ["viewCount", "addToCartCount", "purchaseCount", "itemRevenue"],
};
