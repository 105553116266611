import React, { memo, useState } from 'react';
import Calendar from 'react-calendar';
import dayjs from 'dayjs';
import { CInput } from '../../../migration/CInput';

const CustomFilterDatePicker = (props) => {
     const [valueDate, setValueDate] = useState(null);
     const [showDate, setShowDate] = useState(false);
     const { onChange, value, recordProfile, onChangeInput, error, allowFutureDates = false, isDisabled } = props;

     const onChangeStartDate = (e) => {
          const date = dayjs(e).format('YYYY-MM-DD');
          onChange(date);
          setValueDate(e);
          setShowDate(false);
     };

     const dateClick = () => {
          setShowDate(true);
     };
     return (
          <div className="datepicker-wrapper">
               <div className="date-remove">
                    <CInput
                         className={!!error ? ' is-invalid custom-select-invalid' : ''}
                         type="text"
                         id="dateEstimatedStart"
                         name="dateEstimatedStart"
                         placeholder="Date"
                         value={value}
                         onChange={(e) => {
                              if (typeof onChangeInput === 'function') {
                                   onChangeInput(e.target.value);
                              }
                         }}
                         onClick={() => dateClick()}
                         disabled={recordProfile || isDisabled}
                    />
               </div>
               {showDate && <div className="date-range-overlay" onClick={() => setShowDate(false)}></div>}
               {showDate && (
                    <div className="calendar-wrapper">
                         <Calendar
                              onChange={(e) => onChangeStartDate(e)}
                              returnValue="start"
                              // minDate={new Date(newDateRange.startDate)}
                              // maxDate={new Date(newDateRange.endDate)}
                              maxDate={!allowFutureDates ? new Date() : undefined}
                              allowPartialRange={true}
                              value={valueDate}
                         />
                    </div>
               )}
          </div>
     );
};

export default memo(CustomFilterDatePicker);
