import React, { useContext, useState } from 'react';
import { CButton, CCard, CCardBody, CCardHeader, CCol, CForm, CRow } from '@coreui/react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { API_SALESFORCE_CONNECTION, COMPONENT_NAME, SF_CONNECTION_STATUS, EXTERNAL_DATA_CONNECTION_STATUS, RULE_SYNCING_STATUS } from '../../../../../../../../constants';
import { SalesforceDetailDetailContext } from '../Popup/SalesforceConnectAccount';
import { callSalesforceApi } from '../../../../../../../../apiCaller';
import { toast } from 'react-toastify';
import { fetchSalesforceRulesRequest, resetSalesforceSearchLogs, setReloadAccountSalesforce, setSalesforceAccounts } from '../../../../../../../../actions/subscriber';
import { useDispatch, useSelector } from 'react-redux';
import { setFlexibleModal, setRuleHaveEditting } from '../../../../../../../../actions/common';
import { ConfirmSaveChange } from '../../../../../../../general/popup';
import { fetchSalesforceJobsRequest } from '../../../../../../../../actions/external';
import CFormGroup from '../../../../../../../migration/CFormGroup';
import { CInput } from '../../../../../../../migration/CInput';
import { CInvalidFeedback } from '../../../../../../../migration/CInvalidFeedback';
import CSwitch from '../../../../../../../migration/CSwitch';
import CTooltip from '../../../../../../../migration/CTooltip';

const getMaxDailyAPICallSchema = (maxValue, minValue) =>
     Yup.number()
          .transform((value, originalValue) => {
               if (typeof originalValue === 'string') {
                    return Number(originalValue.replace(/,/g, ''));
               }

               return value;
          })
          .typeError('You must specify a number')
          .positive('Must be a positive number')
          .integer('Must be an integer')
          .min(minValue, `Recommend no lower than ${minValue}`)
          .max(maxValue, `Recommend no higher than ${maxValue}`)
          .required('API Call Limit is required');

const ConnectionSettingsSalesforce = ({ idEdit }) => {
     const dispatch = useDispatch();
     const { accountId } = useSelector((state) => state.subscriber.activeAccount);
     // const SFjobs = useSelector((state) => state.subscriber.jobs);
     const listAccountConnected = useSelector((state) =>
          state.subscriber.accountExternalData ? state.subscriber.accountExternalData.salesforceAccounts : []
     );
     const SFlogs = useSelector((state) => state.subscriber.accountExternalData.SFJobs);
     const salesForeRulesPaging = useSelector((state) => state.subscriber.accountExternalData.salesForeRulesPaging);
     const { activeStep, setActiveStep, stepsData, setStepsData, initStepsData, setInitStepsData } = useContext(SalesforceDetailDetailContext);
     const [isLoading, setIsLoading] = useState(false);
     const [showPopupDelete, setShowPopupDelete] = useState(false);

     const connectionId = stepsData.id;
     // const objects = typeof stepsData.objects === 'string' ? JSON.parse(stepsData.objects) : stepsData.objects;
     const initialValues = {
          status: [SF_CONNECTION_STATUS.ENABLED, SF_CONNECTION_STATUS.DRAFT].includes(stepsData.status) ? true : false,
          maxDailyAPICall: stepsData.maxDailyAPICall ? Number(stepsData.maxDailyAPICall) : 2000,
     };

     const onSubmit = (values) => {
          const newStatus = values.status ? SF_CONNECTION_STATUS.ENABLED : SF_CONNECTION_STATUS.PAUSED;

          if (idEdit && newStatus === stepsData.status && values.maxDailyAPICall === stepsData.maxDailyAPICall) {
               setActiveStep(4);
               return;
          }

          const newValues = {
               status: newStatus,
               maxDailyAPICall:
                    typeof values.maxDailyAPICall === 'string' ? Number(values.maxDailyAPICall.replace(/,/g, '')) : values.maxDailyAPICall,
               isPauseHistoricalSync: true,
               isPauseDailySync: true,
               // objects: []
          };

          setIsLoading(true);
          callSalesforceApi(`${API_SALESFORCE_CONNECTION}/${connectionId}`, 'PUT', newValues)
               .then((response) => {
                    if (response && response.status === 200) {
                         setStepsData((state) => ({ ...state, ...newValues }));
                         setInitStepsData({ ...stepsData, ...newValues });

                         const newListAccount = listAccountConnected.map((item) =>
                              item.id === connectionId ? { ...item, ...newValues, status: newStatus } : item
                         );

                         dispatch(setSalesforceAccounts(newListAccount));

                         if (idEdit) {
                               dispatch(fetchSalesforceRulesRequest(accountId, false, 1, salesForeRulesPaging.itemsPerPage));
                              
                              toast.success('Update connection success');
                         }

                         setActiveStep(4);
                    }
               })
               .finally(() => {
                    setIsLoading(false);
               });
     };

     const handleDeleteConnection = () => {
          setIsLoading(true);

          callSalesforceApi(`${API_SALESFORCE_CONNECTION}/${idEdit}`, 'DELETE')
               .then((response) => {
                    if (response && response.status === 200) {
                         dispatch(
                              setFlexibleModal({
                                   show: false,
                                   ruleId: '',
                                   component: '',
                                   ruleIdLv2: '',
                                   componentLv2: '',
                              })
                         );
                         dispatch(setReloadAccountSalesforce());
                         dispatch(fetchSalesforceRulesRequest(accountId, false, 1, salesForeRulesPaging.itemsPerPage));
                         dispatch(fetchSalesforceJobsRequest(accountId, SFlogs.activePage, SFlogs.itemsPerPage));
                         dispatch(resetSalesforceSearchLogs());
                         toast.success('Delete connection success');
                    } else {
                         toast.error('Delete connection failed!');
                    }
               })
               .finally(() => {
                    setIsLoading(false);
               });
     };

     // Convert number commas
     // const customHandleChange = (event, setFieldValue) => {
     //      setFieldValue('apiCallLimit', event.target.value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ","))
     // }

     const formatNumber = (value) => {
          return value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
     };

     const handleChangeCustom = (e, setFieldValue) => {
          const { name, value } = e.target;
          const formattedValue = value
               .replace(/,/g, '')
               .toString()
               .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          setFieldValue(name, formattedValue);
     };

     const checkStepPassed = () => {
          let passed = true;
          const { status, name, salesforceId } = stepsData;
          if (!salesforceId) {
               return false;
          }
          if (!name || status === SF_CONNECTION_STATUS.DRAFT) {
               passed = activeStep > 2 || !!initStepsData.name;
          }

          return passed;
     };

     // const getRelatedObjects = (objects = []) => {
     //      const relatedObjectData = {
     //          Account: 'AccountContactRelation',
     //          Opportunity: 'OpportunityContactRole',
     //          Contract: 'ContractContactRole',
     //          Campaign: 'CampaignMember',
     //          Event: 'EventRelation',
     //      };
      
     //      return objects.map(obj => relatedObjectData[obj]).filter(Boolean);
     // };

     // let jobLength = 0;

     // if (Array.isArray(objects) && objects.length > 0) {
     //      const relatedObjects = getRelatedObjects(objects);
     //      const allObjects = new Set([...objects, ...relatedObjects]);

     //      jobLength = allObjects.size;
     // }

     // const jobConnection = SFjobs ? SFjobs.filter(item => item.connectionId === stepsData.id) : [];
     // const hasSyncData = stepsData && stepsData.jobs && stepsData.jobs.length > 0;
     // const hasFininedData = jobConnection.some((_job) => _job.status !== SF_JOB_STATUS.FINISHED);
     // const syncDataDisabled = SFjobs.filter(item => item.connectionId === stepsData.id).some((_job) => _job.status !== SF_JOB_STATUS.FINISHED) || (hasSyncData && (hasFininedData || jobConnection.length !== jobLength));

     const isDisabledPaused = stepsData.rule?.filter(item => item.statusSyncing === RULE_SYNCING_STATUS.SYNCING)
     const numberOfRequest = Number(stepsData.numberOfRequest) > Number(initialValues.maxDailyAPICall) ? Number(initialValues.maxDailyAPICall) : Number(stepsData.numberOfRequest);

     return (
          <>
               <CCard className={activeStep === 3 ? 'show' : 'hide'}>
                    <CCardHeader>
                         {activeStep !== 3 ? (
                              <div className="rule-step d-inline-flex justify-content-between w-100">
                                   <h5 className="mb-0 inactive">Step 3: Connection Settings</h5>
                                   {checkStepPassed() && (
                                        <div className="d-flex">
                                             {/* <div className="pr-3">
                                             <span>Status: {stepsData.status === 0 ? 'Active' : 'Paused'}</span>
                                        </div> */}
                                             <div className="d-inline-flex align-items-center">
                                                  <CButton className="btn-edit" onClick={() => setActiveStep(3)}>
                                                       Edit
                                                  </CButton>
                                             </div>
                                        </div>
                                   )}
                              </div>
                         ) : (
                              <h5 className="mb-0">Step 3: Connection Settings</h5>
                         )}
                    </CCardHeader>
                    {activeStep === 3 && (
                         <CCardBody>
                              <Formik
                                   initialValues={initialValues}
                                   onSubmit={onSubmit}
                                   validateOnBlur={false}
                                   validationSchema={
                                        stepsData.maxAPIUsage
                                             ? Yup.object().shape({
                                                       maxDailyAPICall: getMaxDailyAPICallSchema(stepsData.maxAPIUsage, numberOfRequest),
                                                  })
                                             : Yup.object().shape({
                                                       maxDailyAPICall: Yup.mixed().required('API Call Limit is required'),
                                                  })
                                   }
                              >
                                   {({ values, handleSubmit, handleChange, errors, setFieldValue }) => {
                                        if (idEdit) {
                                             if (
                                                  (typeof values.maxDailyAPICall === 'string'
                                                       ? Number(values.maxDailyAPICall.replace(/,/g, ''))
                                                       : values.maxDailyAPICall) === initStepsData.maxDailyAPICall &&
                                                  (values.status ? SF_CONNECTION_STATUS.ENABLED : SF_CONNECTION_STATUS.PAUSED) ===
                                                       initStepsData.status
                                             ) {
                                                  dispatch(setRuleHaveEditting({ show: false, type: COMPONENT_NAME.SALESFORCE_CONNECTED_ACCOUNTS }));
                                             } else {
                                                  dispatch(setRuleHaveEditting({ show: true, type: COMPONENT_NAME.SALESFORCE_CONNECTED_ACCOUNTS }));
                                             }
                                        }

                                        return (
                                             <CForm onSubmit={handleSubmit} noValidate>
                                                  <CRow>
                                                       <CCol>
                                                            <p>
                                                                 Manage the settings for this connection. (
                                                                 {formatNumber(numberOfRequest || 0)} /{' '}
                                                                 {formatNumber(values.maxDailyAPICall)})
                                                            </p>
                                                       </CCol>
                                                  </CRow>
                                                  <CRow style={{ marginBottom: '20px' }}>
                                                       <CCol lg="3">
                                                            <p>Set a daily API call limit.</p>
                                                       </CCol>
                                                       <CCol lg="2">
                                                            <CFormGroup>
                                                                 <CInput
                                                                      id="maxDailyAPICall"
                                                                      type="text"
                                                                      name="maxDailyAPICall"
                                                                      invalid={!!errors.maxDailyAPICall}
                                                                      value={formatNumber(values.maxDailyAPICall)}
                                                                      onChange={(e) => handleChangeCustom(e, setFieldValue)}
                                                                 />
                                                                 <CInvalidFeedback>{errors.maxDailyAPICall}</CInvalidFeedback>
                                                            </CFormGroup>
                                                       </CCol>
                                                       <CCol lg="7">
                                                            <p>
                                                                 We recommend no higher than 20,000 - this will typically allow you to avoid hitting
                                                                 API limits inside your Salesforce Account.
                                                            </p>
                                                       </CCol>
                                                  </CRow>

                                                  {stepsData.status !== SF_CONNECTION_STATUS.CLOSED && (
                                                       <CRow>
                                                            <CCol lg="3">
                                                                 <p>
                                                                      Status:{' '}
                                                                      {values.status
                                                                           ? EXTERNAL_DATA_CONNECTION_STATUS.active
                                                                           : EXTERNAL_DATA_CONNECTION_STATUS.paused}
                                                                 </p>
                                                            </CCol>

                                                            <CCol lg="2">
                                                                 {isDisabledPaused?.length ? (
                                                                      <CTooltip
                                                                           content={`An object in the process of syncing cannot be paused.`}
                                                                           placement="right"
                                                                           style={{ zIndex: 10000 }}
                                                                      >
                                                                           <div className={`option-item switch-item`} style={{ width: 'max-content' }}>
                                                                                <span className="option">
                                                                                     <CSwitch
                                                                                          id="status"
                                                                                          color={values.status ? 'success' : 'light'}
                                                                                          checked={!!values.status}
                                                                                          value={values.status ? 'on' : 'off'}
                                                                                          shape="pill"
                                                                                          tabIndex="0"
                                                                                          size="sm"
                                                                                          onChange={handleChange}
                                                                                          disabled={isDisabledPaused?.length}
                                                                                     />
                                                                                </span>
                                                                           </div>
                                                                      </CTooltip>
                                                                 ) : (
                                                                      <div className={`option-item switch-item`}>
                                                                           <span className="option">
                                                                                <CSwitch
                                                                                     id="status"
                                                                                     color={values.status ? 'success' : 'light'}
                                                                                     checked={!!values.status}
                                                                                     value={values.status ? 'on' : 'off'}
                                                                                     shape="pill"
                                                                                     tabIndex="0"
                                                                                     size="sm"
                                                                                     onChange={handleChange}
                                                                                />
                                                                           </span>
                                                                      </div>
                                                                 )}
                                                            </CCol>
                                                            <CCol lg="7">
                                                                 <p>
                                                                      Pausing this connection will stop the import of data, but current data will not
                                                                      be removed. Once you enable this connection again, we will attempt to import
                                                                      data from the timestamp of pausing.
                                                                 </p>
                                                            </CCol>
                                                       </CRow>
                                                  )}

                                                  {idEdit && (
                                                       <CRow>
                                                            <CCol lg="5">
                                                                 <CButton
                                                                      className="btn-delete-connect pl-0"
                                                                      onClick={() => setShowPopupDelete(true)}
                                                                 >
                                                                      Delete Connection
                                                                 </CButton>
                                                            </CCol>
                                                            <CCol lg="7">
                                                                 <p>
                                                                 Deleting this connection will permanently remove it from your ListenLayer account, and you will lose all object and field settings. We do not recommend doing this without the help of support.
                                                                 </p>
                                                            </CCol>
                                                       </CRow>
                                                  )}

                                                  <CButton type="submit" className="px-4 btn-next-step" color="primary" disabled={isLoading}>
                                                       {isLoading ? (
                                                            <span className="dots-waiting">Waiting</span>
                                                       ) : idEdit ? (
                                                            'Save And Next'
                                                       ) : (
                                                            'NEXT STEP'
                                                       )}
                                                  </CButton>
                                             </CForm>
                                        );
                                   }}
                              </Formik>
                         </CCardBody>
                    )}
               </CCard>
               <ConfirmSaveChange
                    show={showPopupDelete}
                    onClose={() => setShowPopupDelete(false)}
                    isLoading={isLoading}
                    onAccept={handleDeleteConnection}
                    title={'Are You Sure You Want to Delete Connection ?'}
               >
                    <p>You are about to delete this Salesforce Connection.</p>
               </ConfirmSaveChange>
          </>
     );
};

export default ConnectionSettingsSalesforce;
