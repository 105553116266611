import React from 'react';

import {
     Chart,
     ChartSeries,
     ChartSeriesItem,
     ChartCategoryAxis,
     ChartCategoryAxisItem,
     ChartLegend,
     ChartValueAxis,
     ChartValueAxisItem,
     ChartTooltip,
} from '@progress/kendo-react-charts';
import { convertReportDuration } from '../../../../../utils';

const LineChart = ({ data, sharedTooltipRender, segmentLength }) => {
     const { vertical, categories, series } = data
     function manipulateNumber(number) {
          const numString = Math.round(number).toString();
          const numLength = numString.length;

          let operation = 0;

          if (numLength === 2) {
               operation = 5;
          } else if (numLength === 3) {
               operation = 50;
          } else if (numLength === 4) {
               operation = 500;
          } else if (numLength >= 5) {
               operation = 5000;
          }

          if (number < 10) {
               operation = 2;
          }

          return number + operation;
     }

     function formatNumberWithCommas(number) {
          return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
     }

     function isNumber(value) {
          return !isNaN(Number(value));
     }

     function formatNumber(value) {
          if (!isNumber(value)) {
               return String(value);
          } else {
               return formatNumberWithCommas(Number(value));
          }
     }

     const checkMetricTime = (name) => {
          const timeMetrics = ['Avg Session Duration', 'Session Duration', 'Avg Visible Time on Page', 'Avg Visible Time/Page'];
          return timeMetrics.includes(name);
     };

     const formatAxisValue = (e, nameAxis) => {
          const name = nameAxis;

          if (name.includes('Rate')) {
               return `${e.value}%`;
          } else if (checkMetricTime(name)) {
               return `${convertReportDuration(e.value)}`;
          }

          return `${formatNumber(e.value)}`;
     };

     return (
          <Chart className="chart">
               <ChartTooltip shared={true} render={sharedTooltipRender} />
               <ChartValueAxis>
                    {vertical &&
                         vertical.map((item, index) => {
                              return (
                                   <ChartValueAxisItem
                                        key={item.name + index}
                                        name={item.name}
                                        color={item.color}
                                        min={0}
                                        max={manipulateNumber(Math.max(...item.data))}
                                        labels={{
                                             content: (e) => formatAxisValue(e, item.name),
                                             font: '400 10px Roboto, serif',
                                             rotation: 'auto',
                                        }}
                                        line={true}
                                        majorGridLines={{
                                             color: '#F1F3F5',
                                             width: 1,
                                             dashType: 'solid',
                                        }}
                                   />
                              );
                         })}
               </ChartValueAxis>
               <ChartLegend position="bottom" orientation="horizontal" visible={false} />
               <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                         labels={{
                              format: 'd MMM, yyyy',
                              rotation: 'auto',
                              font: '400 9px Roboto, serif',
                              visible: true,
                         }}
                         categories={categories}
                         axisCrossingValues={[0, categories.length]}
                         crosshair={{ visible: true, opacity: 0.2, width: 10, color: '#E2E6E9' }}
                         maxDivisions={categories.length > 5 ? (segmentLength > 1) ? 4 : 6 : 0}
                         color="#4B5563"
                         line={true}
                         majorGridLines={{ visible: false }} // Ẩn các đường gridlines lớn
                         minorGridLines={{ visible: false }} // Ẩn các đường gridlines nhỏ
                    />
               </ChartCategoryAxis>
               <ChartSeries>
                    {series.map((item, idx) => (
                         <ChartSeriesItem
                              key={idx}
                              axis={item.name}
                              type={item.type}
                              data={item.data}
                              name={item.name}
                              color={item.color}
                              width={4}
                              gap={3}
                              spacing={0}
                              markers={{
                                   visible: false, // Hiển thị điểm chốt
                                   // size: 10, // Kích thước của điểm chốt
                                   background: '#fff', // Màu nền của điểm chốt
                                   border: {
                                        color: item.color, // Màu viền của điểm chốt
                                        //   width: 2, // Độ dày của viền
                                   },
                                   type: item.typeMarker, // Hình dạng của điểm chốt
                                   // visualViewport: customMarker,
                              }}
                              icon={item.icon}
                         />
                    ))}
               </ChartSeries>
          </Chart>
     );
}

export default LineChart;
