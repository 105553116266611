// common.js
export const TOGGLE_THEME = 'toggle_theme';
export const SET_ASIDE_SHOW = 'set_aside_show';
export const SET_SIDEBAR_SHOW = 'set_sidebar_show';
export const SET_OVERLAY = 'set_overlay';
export const SET_USER = 'set_user';
export const SET_USER_ROLE = 'set_user_role';
export const SET_FLEXIBLE_MODAL = 'set_flexible_modal';
export const SET_FLEXIBLE_MULTI_MODAL = 'set_flexible_multi_modal';
export const SET_VIEWED_RULES = 'set_viewed_rules';
export const SET_UNSAVED_CHANGES_RULE = 'set_unsaved_changes_rule';
export const SET_RULE_MULTI_HAVE_EDITING = 'SET_RULE_MULTI_HAVE_EDITING';
export const SET_RULE_HAVE_EDITTING = 'set_rule_have_editting';
export const NEED_FETCH_RULE = 'theme_need_fetch_rule';

// subscriber.js
export const SET_STEP_NOT_SHOW_ANIMATION_ROADMAP = 'set_step_not_show_animation_roadmap';
export const SET_SUBSCRIBER_STATE = 'set_subscriber_state';
export const SET_ACCOUNTS = 'set_accounts';
export const SET_ACTIVE_ACCOUNT = 'set_active_account';
export const SET_ROUTES = 'set_routes';
export const SET_ACCOUNT_LISTENERS = 'set_account_listeners';
export const SET_ACTIVE_LISTENERS = 'set_active_listeners';
export const SET_ACTIVE_LISTENER = 'set_active_listener';
export const SET_ACCOUNT_DESTINATION = 'set_account_destination';
export const SET_SPECIFIC_FEATURES = 'set_specific_features';
export const SET_USED_ITEMS = 'set_used_items';
export const UPGRADE_ACCOUNT = 'upgrade_account';
export const SET_DOMAINS = 'set_domains';
export const SET_DISABLE = 'set_disable';
export const SET_OWNER = 'set_owner';
export const SET_MANAGERS = 'set_managers';
export const SET_PACKAGES = 'set_packages';
export const SET_MAX_PACKAGE = 'set_max_package';
export const SET_NEXT_PACKAGE_INDEX = 'set_next_package_index';
export const SET_LIST_CUSTOM_VARIABLES = 'set_list_custom_variables';
export const SET_LIST_BROWSER_VARIABLES = 'set_list_browser_variables';
export const SET_EVENT_OPTIONS = 'set_event_options';
export const SET_CUSTOM_REPORT = 'set_custom_report';
export const SET_SAVED_REPORT = 'set_saved_report';
export const SET_NATIVE_ECOMMERCE = 'set_native_ecommerce';
export const SET_EVENT_NATIVE_OPTIONS = 'set_event_native_options';
export const SET_ALL_EVENTS = 'set_all_event_options';
export const SET_VARIABLE_OPTIONS = 'set_variable_options';
export const SET_SELECTOR_OPTIONS = 'set_selector_options';
export const SET_CUSTOM_VARIABLE_OPTIONS = 'set_custom_variable_options';
export const SET_BROWSER_VARIABLE_OPTIONS = 'set_browser_variable_options';
export const SET_FEATURES = 'set_features';
export const SET_CUSTOM_VARIABLE_RULES = 'set_custom_variable_rules';
export const SET_INITIAL_CUSTOM_VARIABLE_RULES = 'set_initial_custom_variable_rules';
export const SET_LISTENER_EVENTS = 'set_listener_events';
export const SET_LISTENER_EVENTS_LOADING = 'set_listener_events_loading';
export const SET_LISTENER_EVENTS_NATIVE = 'set_listener_events_native';
export const SET_NATIVE_BROWSER_STORAGE = 'set_native_browser_storage';
export const SET_LISTENER_VARIABLES = 'set_listener_variables';
export const SET_LISTENER_SELECTORS = 'set_listener_selectors';
export const SET_LOADINGS = 'set_loadings';
export const SET_TOAST_IS_OPEN = 'set_toast_is_open';
export const SET_FILTER_SHOW_ENABLED_FEATURES = 'set_filter_show_enabled_features';
export const SET_LOOKUP_RULE_ACTIVE_STEP = 'set_lookup_rule_active_step';
export const SET_REMOVED_CUSTOM_VARIABLE_RULES = 'set_removed_custom_variable_rules';
export const RESET_SUBSCRIBER_REDUCER = 'reset_subscriber_reducer';
export const SET_SHOW_HAMBURGER_ICON = 'set_show_hamburger_icon';
export const SET_SHOW_CREATE_ACCOUNT_POPUP = 'set_show_create_account_popup';
export const SET_SHOW_ACCOUNT_DORMANT_POPUP = 'set_show_account_dormant_popup';
export const SET_SHOW_UPGRADE_ACCOUNT_POPUP = 'set_show_upgrade_account_popup';
export const SET_SHOW_UPGRADE_ACCOUNT_SCHEDULE_POPUP = 'set_show_upgrade_account_schedule_popup';
export const SET_SHOW_COMPARE_PLANS_POPUP = 'set_show_compare_plans_popup';
export const SET_SHOW_BLOCK_ACCOUNT_POPUP = 'set_show_block_account_popup';
export const SET_SHOW_BLOCK_SETUP_GUIDE_POPUP = 'set_show_block_setup_guide_popup';
export const SET_ACCOUNT_PASTDUE = 'set_account_pastdue';
export const SET_LIMITATION_TYPE = 'set_limitation_type';
export const RESET_LIMITTAION_TYPE = 'reset_limitation_type';
export const SET_INVOICES = 'set_invoices';
export const SET_AUTOMATIC_VARIABLE_RELOAD = 'set_automatic_variable_reload';
export const SET_SELECTOR_VARIABLE_RELOAD = 'set_selector_variable_reload';
export const SET_GLOBAL_REPORTING = 'set_global_reporting';
export const SET_EVENT_CHART = 'set_event_chart';
export const SET_CHART_OPTIONS = 'set_chart_options_ll';
export const SET_ACTIVE_FEATURES = 'set_active_features';
export const SET_EDITING_RULE_RELOAD = 'set_editing_rule_reload';
export const SET_BROWSER_RULES = 'set_browser_rules';
export const SET_MONITORING_RULES = 'set_monitoring_rules';
export const TOGGLE_ASIDE_LIMITATION_HIT = 'toggle_aside_limitation_hit';
export const SET_SHOW_EVENT_REPORTING_WARNING = 'set_show_event_reporting_warning';
export const SET_VIDEO_PROGRESSES = 'set_video_progresses';
export const SET_VIDEO_ENGAGEMENTS = 'set_video_engagements';
export const SET_REDIRECT_RULES = 'set_redirect_rules';
export const SET_EVENT_TABLE = 'set_event_table';
export const SET_EVENT_TABLE_OF_LISTENERS = 'set_event_table_of_listeners';
export const SET_ACROSS_DOMAINS = 'set_across_domains';
export const SET_POPULATE_RULES = 'set_populate_rules';
export const SET_NATIVE_BROWSER_STORAGE_OPTIONS = 'set_native_browser_storage_options';
export const SET_AUTOMATIC_VARIABLE_WARNING = 'set_automatic_variable_warning';
export const SET_ESTIMATED_TOTAL_EVENTS = 'set_estimated_total_events';
export const SET_SELECTOR_VALUES = 'set_selector_values';
export const SET_VARIABLE_VALUE_CHART = 'set_variable_value_chart';
export const SET_UNSAVED_LOOKUP_TABLE_MODAL = 'set_unsaved_lookup_table_modal';
export const SAVE_TEMP_PUBLISH_DATA = 'save_temp_publish_data';
export const REVERT_PUBLISH_DATA = 'revert_publish_data';
export const SET_NATIVE_BROWSER_ACCOUNT_FEATURES = 'set_native_browser_account_features';
export const SET_TERM_PUBLISH = 'set_term_publish';
export const SET_USER_TERMS = 'set_user_terms';
export const SET_USER_SOURCE_RULES = 'set_user_source_rules';
export const SET_INITIAL_USER_SOURCE_RULES = 'set_initial_user_source_rules';
export const SET_IS_CUSTOMER_SUPPORT_LOGIN_AS = 'set_is_customer_support_login_as';
export const SET_MY_CREATED_TICKETS = 'set_my_created_tickets';
export const SET_OTHER_TICKETS = 'set_other_tickets';
export const SET_SUBSCRIBER_TICKET = 'set_subscriber_ticket';
export const SET_LISTENERS_IN_TICKET = 'set_listeners_in_ticket';
export const SET_SHOW_MASTERCLASS_ASIDE = 'set_show_masterclass_aside';
export const SET_TICKET_INIT = 'set_ticket_init';
export const SET_BUY_OFFICE_HOURS = 'set_buy_office_hours';
export const SET_MASTERCLASS_COURSE = 'set_masterclass_course';
export const SET_MASTERCLASS_CERTIFICATION = 'set_masterclass_certification';
export const SET_OFFICE_HOUR = 'set_office_hour';
export const SET_AVAILABLE_HOURS = 'set_available_hours';
export const SET_ACCOUNT_REDEEMED_COUPONS = 'set_account_redeemed_coupons';
export const SET_OFFICE_HOUR_CALENDAR = 'set_office_hour_calendar';
export const SET_LESSON_VIDEO_REF = 'set_lesson_video_ref';
export const SET_DATA_WEATHER_TEMPERATURE = 'set_data_weather_temperature';
export const SET_CUSTOM_FORM_TARGET_RULES = 'set_custom_form_target_rules';
export const SET_ELEMENT_CLICK_TARGET_RULES = 'set_element_click_target_rules';
export const SET_CLICK_RULES = 'set_click_rules';
export const SET_INITIAL_CLICK_RULES = 'set_initial_click_rules';
export const SET_MONITORING_NOTIFICATIONS = 'set_monitoring_notifications';
export const SET_SHOW_ARCHIVE_NOTIFICATIONS = 'set_show_archive_notifications';
export const SET_VISITOR_SESSIONS_UNIQUE = 'set_visitors_sessions_unique';
export const SET_SHOW_PUBLISH_POPUP = 'set_show_publish_popup';
export const SET_SHOW_GET_HELP_LABEL = 'set_show_get_help_label';
export const SET_GTM_CONTAINERS = 'set_gtm_containers';
export const SET_IS_FROM_GET_STARTED = 'set_is_from_get_started';
export const SET_CHECKED_ITEMS_BYPAGE = 'set_checked_items_bypage';
export const SET_SEARCH_KEY = 'set_search_key';
export const SET_CURRENT_PAGE = 'set_current_page';
export const SET_CONSENT_LISTENER_ACCOUNT = 'set_consent_listener_account';
export const SET_CONSENT_RULES_ACCOUNT = 'set_consent_rules_account';
export const SET_DOMAINS_PREVIEW = 'set_domains_preview';
export const SET_DOMAIN_PREVIEW = 'set_domain_preview';
export const SET_TOGGLE_PREVIEW_POPUP = 'set_toggle_preview_popup';
export const SET_TURNOFF_PREVIEW_POPUP = 'set_turnoff_preview_popup';
export const SET_POPUP_DOMAIN_PREVIEW = 'set_popup_domain_preview_popup';
export const SET_POPUP_CREATE_VARIABLE = 'set_popup_create_variable';
export const SET_NEW_CUSTOM_VARIABLE = 'set_new_custom_variable';
export const SET_POPUP_CREATE_BROWSER_VARIABLE = 'set_popup_create_browser_variable';
export const SET_POPUP_CONFIRM_ADD_SCRIPT = 'set_popup_confirm_add_script';
export const SET_CONFIRM_ADD_SCRIPT = 'set_confirm_add_script';
export const SET_POPUP_CONFIGURATION_REQUIRED = 'set_popup_configuration_required';
export const SET_LISTENER_PREVIEW = 'set_listener_preview';
export const SET_ACCOUNT_PREVIEW = 'set_account_preview';
export const SET_NEW_LAYOUT = 'set_new_layout';
export const RESET_PREVIEW = 'reset_preview';
export const SET_TRIGGER_RULES = 'set_trigger_rules';
export const SET_TRIGGERS_NAME_RULES = 'set_trigger_names_rules';
export const SET_INITIAL_TRIGGER_RULES = 'set_initial_trigger_rules';
export const SET_CONVERSION_RULES = 'set_conversion_rules';
export const SET_INITIAL_CONVERSION_RULES = 'set_initial_conversion_rules';
export const SET_DATA_ACTIONS_RULES = 'set_data_actions_rules';
export const SET_ALL_DATA_ACTION_RULES = 'set_all_data_actions_account_rules';
export const SET_GTM_WIZARD = 'set_gtm_wizard';
export const SET_GTM_VARIABLES_IN_LL = 'set_gtm_variables_in_LL';
export const SET_GTM_TRIGGERS_IN_LL = 'set_gtm_triggers_in_LL';
export const SET_GTM_TAGS_IN_LL = 'set_gtm_tags_in_LL';
export const SET_GTM_CHANGELOG = 'set_gtm_changelog';
export const SET_ACCORDION = 'set_accordion';
export const SET_PREVIEW_BROWSER_RULE = 'set_preview_browser_rule';
export const SET_PREVIEW_CUSTOM_RULE = 'set_preview_custom_rule';
export const RELOAD_DATA_ACTIONS = 'reload_data_actions';
export const RELOAD_TRIGGER_RULES = 'reload_trigger_rules';
export const RELOAD_USER_SOURCE = 'reload_user_source';
export const RELOAD_CONVERSION_RULES = 'reload_conversion_rules';
export const RELOAD_DESTINATION_CONVERSIONS = 'reload_destination_conversions';
export const RELOAD_DESTINATION_ECOMMERCE = 'reload_destination_ecommerce';
export const RELOAD_LOOKUP_TABLE = 'reload_lookup_table';
export const SET_PREVIEW_OPTIONS = 'set_preview_options';
export const SET_TOUR = 'set_tour';
export const PUSHER_SCHEDULE_UPGRADE = 'pusher_schedule_upgrade';
export const SET_CLICK_IDS = 'set_click_ids';
export const SET_CREATED_ACCOUNT = 'set_created_account';
export const SET_SIGN_UP_STEP = 'set_sign_up_step';
export const SET_GTM_ACCOUNTS = 'set_gtm_accounts';
export const SET_TRIGGER_EVENT_ECOMMERCE = 'set_trigger_event_ecommerce';
export const SET_ENGAGEMENT_RULES = 'set_engagement_rules';
export const SET_INITIAL_PERSONAL_DATA = 'set_initial_personal_data';
export const SET_CUSTOM_DESTINATION_MODAL = 'set_custom_destination_modal';
export const SET_DORMANT_ACCOUNT = 'set_dormant_account';
export const SET_SHOW_IGNORED_FORM = 'set_show_ignored_form';
export const SET_SHOW_IGNORED_INVENTORY_FORM = 'set_show_ignored_inventory_form';
export const SET_APPLY_CHILD = 'set_apply_child';
export const SET_APPLY_REDIRECT = 'set_apply_redirect';
export const SET_FORM_CATEGORY = 'set_form_category';
export const SET_CUSTOM_CATEGORY = 'set_custom_categories';
export const SET_FORM_REQUIRING_ACTION = 'set_form_requiring_action';
export const FORM_REQUIRING_SET_ALL_FORMS = 'requiring_set_all_form_requiring_action';
export const SET_COUNT_FORM_REQUIRING = 'set_count_form_requiring';
export const SET_FORM_IGNORED = 'set_form_ignored';
export const SET_COUNT_FORM_IGNORED = 'set_count_form_ignored';
export const SET_FORM_INVENTORY = 'set_form_inventory';
export const SET_COUNT_FORM_INVENTORY = 'set_count_form_inventory';
export const SET_STEPS_WIZARD_CATEGORIES = 'set_step_forms_wizard_categories';
export const SET_STEPS_CUSTOM_FORMS = 'set_step_custom_forms';
export const SET_LISTENER_FORMS_ENABLED = 'set_listener_forms_enabled';
export const SET_SWITCH_TYPE_WIZARD = 'set_switch_type_wizard';
export const SET_DATE_REPORTS = 'set_date_reports';
export const SET_DATE_RANGE_RECORD = 'set_date_range_record';
export const SET_SHOW_IFRAME_CUSTOM_FORMS = 'set_show_iframe_custom_forms';
export const SET_LIST_FEATURE_CUSTOM_FORMS = 'set_list_feature_custom_forms';
export const SET_WIDTH_COLUMN_REPORTS = 'set_width_column_reports';
export const SET_COMPLETE_SETUP_GUIDE = 'set_complete_setup_guide';
export const SET_FEATURE_INSTALL_GOALS = 'set_feature_install_goals';
export const SET_FEATURE_CUSTOM_OPTINAL = 'set_feature_custom_optinal';
export const SET_FEATURE_FORM_CONVERSION = 'set_feature_form_conversion';
export const SET_FEATURE_LEARN_DATA = 'set_feature_learn_data';
export const SET_FEATURE_LEARN_SEDING_DATA = 'set_feature_learn_sending_data';
export const SET_FEATURE_CONFIGURE_DESTINATION = 'set_feature_configure_destination';
export const SET_SETUP_GUIDE_ROADMAP = 'set_setup_guide_roadmap';
export const SET_SETUP_GUIDE_ROADMAP_INCREASE = 'set_setup_guide_roadmap_increase';
export const SET_SETUP_GUIDE_ROADMAP_DECREASE = 'set_setup_guide_roadmap_decrease';
export const SET_SHOW_VIDEO = 'set_show_video';
export const SET_SHOW_STEP_FORM_CATEGORY = 'set_show_step_form_category';
export const SET_SHOW_STEP_CUSTOM_FORM = 'set_show_step_custom_form';
export const SET_MANAGE_GOALS_TYPES = 'set_manage_goals_types';
export const SET_SHOW_ROADMAP_MINIMIZE = 'set_show_roadmap_minimize';
export const SET_SHOW_CUSTOM_FORM = 'set_show_custom_form';
export const SET_SHOW_CATEGORY_FORM = 'set_show_category_form';
export const SET_DATA_SETUP_GUIDE_COMPLETED = 'set_data_setup_guide_completed';
export const SET_PARENT_SETUP_GUIDE_COMPLETED = 'set_parent_setup_guide_completed';
export const SET_DATA_CHILD_SETUP_GUIDE_COMPLETED = 'set_data_child_setup_guide_completed';
export const SET_SKIP_WIZARD_FORM_CATEGORY = 'set_skip_wizard_form_category';
export const SET_BROWSE_LISTENER_FORM_CATEGORY = 'set_browse_listener_form_category';
export const SET_SHOW_ROADMAP = 'set_show_roadmap';
export const SET_SHOW_TOUR_FROM_ROADMAP = 'set_show_tour_from_roadmap';
export const SET_SHOW_TOUR_COMPETED_WIZARD = 'show_tour_completed_wizard';
export const FORM_CATEGORY_SET_LIST_FORM_CHILDS = 'form_category_set_list_form_childs';
export const FORM_CATEGORY_UPDATE_FORM_CHILD = 'form_category_update_form_child';
export const FORM_CATEGORY_SET_FORM_EDIT = 'form_category_set_form_edit';
export const FORM_CATEGORY_SET_NUMBER_OFFSET = 'form_category_set_number_offset';
export const FORM_CATEGORY_SET_ALL_FORMS_INVENTORY = 'form_category_set_all_forms';
export const FORM_REQUIRING_SET_FORM_EDIT = 'form_requiring_set_forms_edit';
export const FEW_FORMS_SET_FORM_EDIT = 'few_forms_set_forms_edit';
export const FORM_IGNORE_SET_FORM_EDIT = 'form_ignore_set_forms_edit';
export const FORM_CATEGORY_SET_ALL_FORMS_REQUIRING = 'form_category_set_all_forms_requiring';
export const FORM_CATEGORY_SET_ALL_FORMS_IGNORE = 'form_category_set_all_forms_ignored';
export const SET_FORM_DRAFT = 'set_forms_draft';
export const FORM_CATEGORY_SET_FILTERS = 'form_category_set_filters';
export const FORM_CATEGORY_SET_FILTERS_EDIT = 'form_category_set_filters_edit';
export const SET_REDUX_ACTIVE_DROPDOWN = 'set_redux_active_dropdown';
export const SET_LOAD_FORM_CHILD_REQUIRING = 'set_load_form_child_requiring';
export const SET_OPEN_FROM_CONFIG_CONVERSION = 'set_open_from_config_conversion';
export const SET_SHOW_POPUP_CONFIG_CONVERSION = 'set_show_popup_config_conversion';
export const SET_STEPS_CONVERSION = 'set_step_conversion';
export const SET_SKIP_FORM_LISTENER = 'set_skip_form_listener';
export const SET_ACCOUNT_EXTERNAL_DATA_SOURCES = 'set_account_external_data_sources';
export const SET_MONITORING_FORM_CATEGORY = 'set_monitiring_form_category';
export const SET_LIST_CHANNELS = 'set_list_channels';
export const SET_CUSTOM_USER_SOURCES = 'set_custom_user_sources';
export const SET_LIST_USER_SOURCES = 'set_list_user_sources';
export const SET_MAX_STEP_FORM = 'set_max_step_form';
export const SET_MAX_STEP_RECORD_PROFILE = 'set_max_step_record_profile';
export const SET_GOOGLE_ADS_ACCOUNTS = 'set_google_ads_accounts';
export const SET_SALESFORCE_ACCOUNTS = 'set_salesforce_accounts';
export const SET_CUSTOMER_IDS_RESPONSE = 'SET_CUSTOMER_IDS_RESPONSE';
export const SET_SHOW_HISTORICAL_POPUP = 'SET_SHOW_HISTORICAL_POPUP';
export const SET_DATA_HISTORICAL_SALESFORCE = 'SET_DATA_HISTORICAL_SALESFORCE';
export const SET_REDUX_RELOAD_TABLE_REPORT = 'set_reload_report_table_data';
export const SET_SALESFORCE_RULES = 'set_salesforce_rules';
export const SET_SALESFORCE_RULES_PAGING = 'set_salesforce_rules_paging';
export const SET_RELOAD_ACCOUNT_SALESFORCE = 'set_reload_account_salesforce';
export const SET_REDUX_RELOAD_TABLE_CHART = 'set_reload_report_table_chart';
export const SET_LIST_SEGMENT = 'set_list_segment';
export const SET_LIST_SEGMENT_REPORT = 'set_list_segment_report';
export const SET_LIST_SEGMENT_APPLY_RELOAD = 'set_list_segment_apply_reload';
export const SET_LIST_SEGMENT_RELOAD = 'set_list_segment_reload';
export const SET_CONVERSIONS_NAME_RULES = 'set_conversions_name_rules';
export const SET_BLOCK_TRIGGER_RULES = 'set_block _trigger_rules';
export const SET_LOADING_FORM_IGNORE = 'set_loading_form_ignore';
export const SET_LIST_SEGMENT_DB = 'set_list_segment_db';
export const SET_DATA_DASHBOARD = 'set_data_dashboard';
export const SET_RELOAD_DATA_DASHBOARD = 'set_reload_data_dashboard';
export const SET_LIST_TABLE_TEMP_QUERY_DATA = 'set_table_temp_query_data';
export const SET_SALESFORCE_SEARCH_LOGS = 'set_salesforce_search_logs';
export const RESET_SALESFORCE_SEARCH_LOGS = 'reset_salesforce_search_logs';
export const SET_LIST_SEVER_SIDE_TRIGGER = 'set_list_sever_side_trigger';
export const SET_LIST_SALES_CONVERSION = 'set_list_sales_conversion';
export const SET_SCHEDULE_SAVED_REPORT = 'set_schedule_saved_report';
// Dashboard v2
export const SET_DB_SEGMENT_SELECTED = 'set_segment_selected';
export const SET_DB_DATE_RANGE = 'set_db_date_range';
export const SET_DB_DATE_RANGE_CONFIG = 'set_db_date_range_config';
export const SET_IS_LOAD_REMOVE_COMPARE = 'set_is_load_remove_compare';
export const SET_LOADING_DB = 'set_loading_db';
export const SET_IS_LOADING_ADD_SEGMENT_CHART = 'set_is_loading_add_segment_chart';
export const SET_IS_LOADING_ADD_SEGMENT_METRIC = 'set_is_loading_add_segment_metric';
export const SET_IS_LOAD_ADD_COMPARE = 'set_is_load_add_compare';
export const SET_SORT_TABLE_DB = 'set_sort_table_db';
export const SET_IS_DONE_SEGMENT = 'set_is_done_segment';
export const SET_IS_READY_REBUILD_SEGMENT = 'set_is_ready_rebuild_segment';

// PUSHER
export const SET_VERSION_PUBLISH = 'set_version_publish';
export const SET_NUMBER_HISTORIES = 'set_number_histories';
export const PUSHER_INCREASE_NUMBER_OF_HISTORIES = 'push_increase_number_of_histories';
export const SET_VERSIONS = 'set_versions';
export const LOAD_VERSION_PUBLISHING = 'load_version_publishing';
export const SET_PUBLISHED_VERSION = 'set_version_published';
export const PUSHER_INVITE_MANAGER_USER = 'pusher_invite_manager_user';
export const PUSHER_REMOVE_MANAGER_USER = 'pusher_remove_manager_user';
export const PUSHER_ADD_RULE = 'pusher_add_rule';
export const PUSHER_UPDATE_RULE = 'pusher_update_rule';
export const PUSHER_UPDATE_RULE_STATUS = 'pusher_update_rule_status';
export const PUSHER_REMOVE_RULE = 'pusher_remove_rule';
export const PUSHER_REORDER_RULES = 'pusher_reorder_rules';
export const SET_INCREMENT_NUMBER_EVENT = 'set_increment_number_event';
export const PUSHER_CHANGE_LISTENER_STATUS = 'pusher_change_listener_status';
export const PUSHER_CHANGE_FEATURE_STATUS = 'pusher_change_feature_status';
export const PUSHER_CHANGE_CHILD_FEATURE_STATUS = 'pusher_change_child_feature_status';
export const PUSHER_CHANGE_ACCOUNT_NAME = 'pusher_change_account_name';
export const PUSHER_UPGRADE_OR_DOWNGRADE_ACCOUNT = 'pusher_upgrade_or_downgrade_account';
export const PUSHER_UPDATE_INVOICE_INFORMATION = 'pusher_update_invoice_information';
export const PUSHER_SAVE_CREDIT_CARD = 'pusher_save_credit_card';
export const PUSHER_SAVE_CARD_TO_CHANGE = 'pusher_save_card_to_change';
export const PUSHER_CHANGE_ACCOUNT_EVENTS = 'pusher_change_account_events';
export const PUSHER_CHANGE_ACCOUNT_VARIABLES = 'pusher_change_account_variables';
export const PUSHER_CREATE_INVOICES = 'pusher_create_invoices';
export const PUSHER_UPDATE_INVOICE = 'pusher_update_invoice';
export const PUSHER_UPDATE_ACCOUNT = 'pusher_update_account';
export const PUSHER_UPDATE_ACCOUNT_USER = 'pusher_update_account_user';
export const PUSHER_UPDATE_FEATURE_SUPPORT = 'pusher_update_feature_support';
export const PUSHER_UPDATE_FEATURE_SUPPORT_CHILD = 'pusher_update_feature_support_child';
export const PUSHER_UPDATE_FEATURE_SUPPORT_UPDATE_VIDEO_PROGRESSES = 'pusher_update_feature_update_video_progresses';
export const PUSHER_INCREASE_NUMBER_OF_EVENTS = 'pusher_increase_number_of_events';
export const PUSHER_RESET_NUMBER_OF_EVENTS = 'pusher_reset_number_of_events';
export const PUSHER_CHANGE_EVENT_CHART = 'pusher_change_event_chart';
export const PUSHER_CHANGE_NUMBER_VISITORS_CHART = 'pusher_change_number_visitor_chart';
export const PUSHER_CHANGE_NUMBER_SESSIONS_CHART = 'pusher_change_number_session_chart';
export const PUSHER_CREATE_NEW_USER_MANAGER = 'pusher_create_new_user_manager';
export const PUSHER_UPDATE_RULE_CONDITIONS = 'pusher_update_rule_conditions';
export const PUSHER_UPDATE_LIST_EVENTS = 'pusher_update_list_events';
export const PUSHER_UPDATE_LIST_DATALAYER_VARIABLES = 'pusher_update_list_datalayer_variables';
export const PUSHER_UPDATE_USER_MANAGER_INFO = 'pusher_update_manager_info';
export const PUSHER_UDPATE_PUBLISHED_ACCOUNTS = 'pusher_update_publised_account';
export const PUSHER_UPDATE_EVENT_TABLE = 'pusher_update_event_table';
export const PUSHER_INVOICE_FINALIZATION = 'pusher_invoice_finalization';
export const PUSHER_RESET_FEATURE_SUPPORT = 'pusher_reset_feature_support';
export const PUSHER_UPDATE_VARIABLE_VALUES = 'pusher_update_variable_value';
export const PUSHER_UPDATE_SELECTOR_VARIABLES = 'pusher_update_selector_variables';
export const PUSHER_UPDATE_SELECTOR_VALUES = 'pusher_update_selector_values';
export const PUSHER_NATIVE_BROWSER_STORAGE_CHANGE = 'pusher_native_browser_storage_change';
export const PUSHER_UPDATE_ACCOUNT_CONSOLE_STATUS = 'pusher_update_account_console_status';
export const PUSHER_ADD_NEW_TICKET_COMMENT = 'pusher_add_new_ticket_comment';
export const PUSHER_RESET_CMT_NUMBER_OF_CLIENT = 'pusher_reset_cmt_number_of_client';
export const PUSHER_UPDATE_AVAILABLE_HOURS = 'pusher_update_available_hours';
export const PUSHER_SET_RELOAD_PARTNER = 'pusher_set_reload_partner';
export const PUSHER_SET_RELOAD_OFFICE_HOUR_CALENDAR = 'pusher_set_office_hour_calendar';
export const PUSHER_UPDATE_DAYS_LENGTH = 'pusher_update_days_length';
export const PUSHER_UPDATE_NOTIFICATION = 'pusher_update_notification';
export const PUSHER_UPDATE_CUSTOM_FORM_RULES = 'pusher_update_custom_form_rules';
export const PUSHER_UPDATE_ELEMENT_CLICK_RULES = 'pusher_update_element_click_rules';
export const PUSHER_CHANGE_ACCOUNT_TIMESTAMPS = 'pusher_change_account_timestamps';
export const PUSHER_UPDATE_PRECISE_LOCATION_CONFIG = 'pusher_update_precise_location_config';
export const PUSHER_UPDATE_LISTENER_FOR_ROUTES = 'pusher_update_listener_for_routes';
export const PUSHER_CHANGE_VISITORS_AND_SESSIONS_PER_MONTH = 'pusher_change_visitors_and_sessions_per_month';
export const PUSHER_CONSENT_RULE_CHANGED = 'pusher_consent_rule_changed';
export const PUSHER_INCREASE_NUMBER_OF_FAKE_VISITORS_BY_1 = 'pusher_increase_number_of_fake_visitors_by_1';
export const PUSHER_SET_ACCOUNT_COMPLETED_STEPS = 'pusher_set_account_completed_step';
export const PUSHER_UPDATE_PERSONAL_DATA = 'pusher_update_personal_data';
export const PUSHER_UPDATE_SEGMENT = 'pusher_update_segment';
export const CRAWL_FROM_ACTION = 'crawl_form_action';
export const PUSHER_SET_LIST_TABLE_TEMP_QUERY_DATA = 'pusher_set_table_temp_query_data';
export const SF_GET_LIST_JOBS = 'sf_get_list_jobs';
export const PUSHER_UPDATE_JOBS = 'sf_pusher_update_jobs';
export const PUSHER_UPDATE_PROGRESS_TABLES = 'sf_pusher_update_progress_tables';
export const PUSHER_UPDATE_RUNNING_DAILY_NEW = 'sf_pusher_update_running_daily_new';
export const PUSHER_UPDATE_JOBS_CONNECTION = 'sf_pusher_update_jobs_connection';
export const SF_UPDATE_PROGRESS_RULE = 'sf_update_progress_rule';
export const SF_SET_PROGRESS_RULE = 'sf_set_progress_rule';
export const PUSHER_UPDATE_IS_READY_RULE = 'sf_pusher_update_is_ready_rule';
export const PUSHER_UPDATE_IS_SYNCING = 'sf_pusher_update_is_syncing';

// --------- ADMIN ----------
export const ADMIN_INCREMENT_NUMBER_HISTORIES = 'admin_increment_number_histories';
export const ADMIN_SET_PUSHER_CHANNEL_NAME = 'admin_set_pusher_channel_name';
export const ADMIN_SET_PUBLISHED_ACCOUNTS = 'admin_set_published_accounts';
export const ADMIN_SET_PUBLISHING_ACCOUNTS = 'admin_set_publishing_accounts';
export const ADMIN_SET_ACCOUNT = 'admin_set_account';

// ---------- SUPPORT ----------

export const PUSHER_NEW_TICKET = 'pusher_new_ticket';
export const GET_TICKETS = 'get_tickets';
export const PUSHER_NEW_COMMENT = 'pusher_new_comment';
export const SET_TICKET_DETAIL = 'set_ticket_detail';
export const RESET_NUMBER_COMMENT = 'reset_number_comment';
export const SET_NUMBER_OF_TICKETS = 'set_number_of_tickets';
export const SUPPORT_SET_ACCOUNTS = 'set_accounts';
export const SET_NUMBER_OF_COMMENT_TICKETS = 'set_number_of_comment_tickets';
export const UPDATE_STATUS_TICKET = 'update_status_ticket';

// --------- SCROLL -------------

export const SET_SCROLL_SIDEBAR = 'scroll-sidebar';
