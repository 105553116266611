// ** React Imports
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router';
import Tippy from '@tippyjs/react';

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux';

// ** CoreUI Imports
import CIcon from '@coreui/icons-react';

// ** Component Imports
import { EventExplorerContext } from '../../EventExplorer';
import CreateNewView from '../dialog/CreateNewView';

// ** Constants Imports
import { VIEW_TYPE } from '../../../../../../constants/reportView';
import { CLIENT_REPORT_VIEW, DEFAULT_DATE_FORMAT } from '../../../../../../constants';
import { callCompressTokenApi } from '../../../../../../apiCaller';
import CLabel from '../../../../../migration/CLabel.js';
import { setSavedReport } from '../../../../../../actions/subscriber.js';

const SavedReport = () => {
     const dispatch = useDispatch();
     const location = useLocation();
     const user = useSelector((state) => state.theme.user);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const listSegmentReport = useSelector((state) => state.subscriber.listSegmentReport);
     const listSegmentReportApply = listSegmentReport.filter((item) => item.apply).map((item) => item.id);
     const savedReports = useSelector((state) => state.subscriber.savedReport);
     const {
          scoped,
          reportName,
          showData,
          isMapView,
          viewDefault,
          dateRangeConfig,
          segmentSticky,
     } = useContext(EventExplorerContext);

     // ** State
     const [show, setShow] = useState(false);
     const [data, setData] = useState({});
     const type = VIEW_TYPE.REPORT;

     // ** Methods

     const handleCreateNewView = () => {
          if (!showData || !isMapView) {
               return;
          }
          setShow(true);
     };

     const onCancel = () => {
          setShow(false);
          setData({});
     };

     const onAccept = async (values, { setSubmitting, resetForm }) => {
          if (!data.type) {
               if (values.name.trim() === '') {
                    toast.warn('Name not empty!');
               } else if (savedReports.find((report) => report.name === values.name.trim())) {
                    toast.error('Name already exits!');
                    setSubmitting(false);
               } else {
                    const dataDateRangeConfig = values.dateRangeConfig
                    if (dataDateRangeConfig.dateRanges) {

                         if (dataDateRangeConfig.dateRanges.selection) {
                              dataDateRangeConfig.dateRanges.selection = {
                                   startDate: dataDateRangeConfig.dateRanges.selection.startDate.format(DEFAULT_DATE_FORMAT),
                                   endDate: dataDateRangeConfig.dateRanges.selection.endDate.format(DEFAULT_DATE_FORMAT),
                              };
                         }

                         if (dataDateRangeConfig.dateRanges.compare) {
                              dataDateRangeConfig.dateRanges.compare = {
                                   startDate: dataDateRangeConfig.dateRanges.compare.startDate.format(DEFAULT_DATE_FORMAT),
                                   endDate: dataDateRangeConfig.dateRanges.compare.endDate.format(DEFAULT_DATE_FORMAT),
                              };
                         }
                    }

                    if (dataDateRangeConfig.rolling?.customDateCompare) {
                         if (dataDateRangeConfig.rolling.customDateCompare.selection) {
                              dataDateRangeConfig.rolling.customDateCompare.selection = {
                                   startDate: dataDateRangeConfig.rolling?.customDateCompare.selection.startDate.format(DEFAULT_DATE_FORMAT),
                                   endDate: dataDateRangeConfig.rolling?.customDateCompare.selection.endDate.format(DEFAULT_DATE_FORMAT),
                              };
                         }
                    }

                    dataDateRangeConfig.sticky = dateRangeConfig.sticky

                    const content = {
                         dateRangeConfig: dataDateRangeConfig
                    };

                    delete values.dateRangeConfig

                    if (listSegmentReportApply.length > 0) {
                         content.segment = listSegmentReportApply;
                    }

                    content.segmentSticky = segmentSticky;

                    const payload = {
                         ...values,
                         reportName,
                         scoped,
                         type,
                         content: JSON.stringify(content),
                         accountId: activeAccount.accountId,
                         userId: user.id,
                         shareLink: `${location.pathname}`,
                         viewId : viewDefault.id
                    };

                    await callCompressTokenApi(CLIENT_REPORT_VIEW, 'POST', payload)
                         .then((response) => {
                              if (response.status === 200) {
                                   dispatch(setSavedReport(response.data?.reportViews));
                                   toast.success(response.data.message);
                              } else {
                                   toast.error(response.data.message);
                              }
                         })
                         .finally(() => {
                              setSubmitting(false);
                              setShow(false);
                              resetForm();
                         });
               }
          }
     };
    
     return (
          <>
               {isMapView && (viewDefault.isSystem || viewDefault.isAvailable) ? (
                    <div className="d-flex menu-report-list mr-3" onClick={() => handleCreateNewView()}>
                         <CIcon icon="cilSave" width={18} height={18} />
                         <CLabel className="mb-0">{'Save Report'}</CLabel>
                    </div>
               ) : (
                    <Tippy
                         content={
                              isMapView
                                   ? `This selection is locked because the View you are saving with this report is only visible to you.
                                    If you'd like to create a report that is available to All users, your View must also be available to All users.`
                                   : `You cannot save this report until the view you are on is saved.
                          Choose to create a save view, which allows you to attach column, dimension, and filters settings to the save report.`
                         }
                    >
                         <div className="d-flex menu-report-list mr-3 opacity-25">
                              <CIcon icon="cilSave" width={18} height={18} />
                              <CLabel className="mb-0">{'Save Report'}</CLabel>
                         </div>
                    </Tippy>
               )}

               {!data.type && <CreateNewView show={show} type={type} onAccept={onAccept} onCancel={() => onCancel()} reportName={reportName} scoped={scoped} />}
          </>
     );
};

export default SavedReport;
