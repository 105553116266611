import React, { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';

const TooltipContentTemplate = ({ props, dataCopy = null, showCopyTooltip }) => {
    const title = dataCopy || props.target.getAttribute('data-title');
    const [firstCopy, setFirstCopy] = useState(false);
    const increment = (e) => {
         e.stopPropagation();
         setFirstCopy(true);
         setTimeout(() => {
              setFirstCopy(false);
         }, 1500);
    };

    return (
         <div
              className="tooltip-fields"
              style={{
                   display: 'flex',
                   gap: '10px',
                   padding: '10px',
                   maxWidth: '320px',
                   wordBreak: 'break-all',
                   maxHeight: '400px',
                   overflowY: 'auto',
              }}
         >
              {' '}
              <div style={{ fontWeight: 500, flexGrow: 1, flexBasis: 0, minWidth: 0, wordBreak: 'break-word' }}>{title}</div>
              {showCopyTooltip ? (
                   <CopyToClipboard
                        style={{
                             color: '#20a8d8',
                             backgroundColor: '#fff',
                             borderRadius: '12px',
                             padding: '2px 10px',
                             fontSize: '12px',
                             fontWeight: 600,
                             width: 'max-content',
                             height: 'max-content',
                             cursor: 'pointer',
                        }}
                        text={title}
                   >
                        <div className="icon-copy" onClick={increment}>
                             {firstCopy ? 'Copied' : 'Copy'}
                        </div>
                   </CopyToClipboard>
              ) : (
                   ''
              )}
         </div>
    );
};

export default TooltipContentTemplate